<!-- <div class="card-box" style="max-width: 400px; margin: auto;"> -->
  <!-- <div class="row">
    <label for="name">Email</label>
    <input type="email" class="form-control" id="email" name="email" required [(ngModel)]="email" (keydown.enter)="login()" />
  </div>
  <div class="row">
    <label for="name">Password</label>
    <input type="password" class="form-control" id="password" name="password" required [(ngModel)]="password"
      (keydown.enter)="login()" />
  </div> -->
  <!-- <div class="row" style="position:relative; margin-top: 10px;">
    <div class="pull-right">
      <button class="cursor-pointer btn btn-default btn-sm waves-effect waves-light" data-toggle="modal" data-target="#createAccountModal">
        Create Account
      </button>
      <button type="submit" (click)="login()" class="cursor-pointer btn btn-sm btn-success waves-effect waves-light" style="margin-left: 5px;">
        Sign in
      </button>
    </div>
    <a class="cursor-pointer" data-toggle="modal" data-target="#resetPasswordModal">Forgot your
      password?</a>
  </div> -->
<!-- </div> -->



<div id="createAccountModal" class="modal fade in">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
            <h4 class="modal-title" id="myModalLabel">Create Account</h4>
          </div>
        </div>
      </div>
      <div class="modal-body ">
        <!-- <form (ngSubmit)="createAccount()" #createAccountForm="ngForm">
          <div class="row">
            <div class="form-group col-lg-12">
              <label for="newUsername">Email</label>
              <input type="email" class="form-control" id="newEmail" name="newEmail" required [(ngModel)]="newEmail" />
            </div>
          </div>
          <div class="row">
              <div class="form-group col-lg-12">
                <label for="newPassword">Password</label>
                <input type="password" class="form-control" id="newPassword" name="newPassword" aria-describedby="passwordRules" required [(ngModel)]="newPassword" />
                <small class="text" id="passwordRules">Password must contain 8 characters or more, with at least one uppercase, one numeric and one special character.</small>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-12">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" required 
                [(ngModel)]="confirmPassword" />    
              </div>
            </div>
          <div class="row">
            <div class="col-lg-12">
              <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-success waves-effect waves-light">
                Create Account
              </button>
            </div>
          </div>
        </form> -->
      </div>
    </div>
  </div>
</div>

<div id="resetPasswordModal" class="modal fade in">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              ×
            </button>
            <h4 class="modal-title" id="myModalLabel">Reset Password</h4>
          </div>
        </div>
      </div>
      <div class="modal-body ">
        <!-- <form (ngSubmit)="resetPassword()" #createAccountForm="ngForm">
          <div class="row">
            <div class="form-group col-lg-12">
              <span>Please enter your email to reset your password</span>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12">
              <label for="forgottenEmail">Email</label>
              <input type="email" class="form-control" id="forgottenEmail" name="forgottenEmail" required [(ngModel)]="forgottenEmail" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-success waves-effect waves-light">
                Send email
              </button>
            </div>
          </div>
        </form> -->
      </div>
    </div>
  </div>
</div>