<div class="card-box-password">
    <!-- <div class="row">
        <label for="name">New Password</label>
        <input type="password" class="form-control" id="newPassword" name="newPassword" aria-describedby="passwordRules" required [(ngModel)]="newPassword">
        <small class="text" id="passwordRules">Password must contain 8 characters or more, with at least one uppercase, one numeric and one special character.</small>
    </div>
    <div class="row">
        <label for="name">Confirm password</label>
        <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" required [(ngModel)]="confirmPassword">
    </div>
    <div class="row">
        <div class="pull-right">
            <button type="submit" (click)="resetPassword()" class="cursor-pointer btn btn-sm btn-success waves-effect waves-light">
                Reset Password
            </button>
        </div>
    </div> -->
</div>