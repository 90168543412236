<!-- IN THIS FILE: Top Bar, Sidebar meny and router-outlet -->

<!-- core/navbar.component.html -->
<!-- <nav class="navbar navbar-inverse navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" (click)="toggleState()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a href="/" class="logo">
                <i class="icon-c-logo">
                    <img src="assets/images/logo.png" style="height: 40px; padding-left: 15px">
                </i>
                <span>
                    <img src="assets/images/logo.png" style="height: 40px; padding-left: 15px">
                </span>
            </a>
        </div>
        <div class="collapse navbar-collapse" [ngClass]="{ 'in': isCollapsed }">
            <ul class="nav navbar-nav ">
                <li>
                    <a>Link 1</a>
                </li>
                <li>
                    <a>Link 2</a>
                </li>
            </ul>
        </div>
    </div>
</nav> -->


<!-- Top Bar Start -->
<div class="topbar">

  <div class="topbar-left">
      <a [routerLinkActive]="['active']" [routerLink]="['/']">
          <i class="icon-c-logo">
              <img src="assets/images/logo.png" class="logo">
          </i>
      </a>

  </div>

  <div class="navbar navbar-default" role="navigation">
      <div class="container">
          <div class="">
              <div *ngIf="isLoggedin" class="pull-right pull-right-margin">
                  <span calss="email-style">{{user.email}} </span>
                  <span type="button" (click)="logoutUser()" class="btn btn-default waves-effect logout-button">Log
                      out</span>
              </div>
              <!-- <div class="pull-right">
                  <button class="button-menu-mobile open-left waves-effect waves-light" (click)="toggleState()">
                      <i class="md md-menu"></i>
                  </button>
                  <span class="clearfix"></span>
              </div>

              <ul class="nav navbar-nav hidden-xs">
              </ul> -->
          </div>
      </div>
  </div>



</div>

<!-- ========== Left Sidebar Start ========== -->
<div *ngIf="isLoggedin">
  <div class="left side-menu">
      <div class="sidebar-inner slimscrollleft">
          <!--- Divider -->
          <div id="sidebar-menu">
              <ul>
                  <li class="text-muted menu-title">Navigation</li>
                  <li>
                      <!-- [routerLinkActive] tells element to use active css class when the link is active. Use [routerLink] to define where to route -->
                      <a class="waves-effect" [routerLinkActive]="['active']" [routerLink]="['/start']"
                          [routerLinkActiveOptions]="{exact:true}">
                          <i class="ti-home"></i>
                          <span>Start</span>
                      </a>
                  </li>
                  <li>
                      <a class="waves-effect" [routerLinkActive]="['active']" [routerLink]="['/dashboard']">
                          <i class="md-dashboard"></i>
                          <span type="button" class="dropdown-toggle waves-light" data-toggle="dropdown"
                              aria-expanded="false">Groups</span>
                      </a>
                      <ul class="drop-menu-right waves-light waves-effect subdrop" role="menu">
                          <li *ngFor="let group of groups">
                              <a id="{{group}}" [routerLinkActive]="['active']" [routerLink]="['/dashboard', group.groupName]">{{
                                  (group.groupNickname || group.groupName) | capitalize }}</a>
                          </li>
                      </ul>
                  </li>
                  <li *ngIf="sensors != undefined && sensors.length > 0">
                      <a class="waves-effect" [routerLinkActive]="['active']" [routerLink]="['/sensorview', selectedGroup]">
                          <i class="md-settings-remote"></i>
                          <span type="button" class="dropdown-toggle waves-light" data-toggle="dropdown"
                              aria-expanded="false">{{selectedGroup || capitalize}}</span>
                      </a>
                      <ul class="drop-menu-right waves-light waves-effect subdrop" role="menu">
                          <li *ngFor="let sensor of sensors">
                              <a id="{{sensor}}" [routerLinkActive]="['active']" [routerLink]="['/sensorview', selectedGroup, sensor]">{{
                                  sensor | capitalize }}</a>
                          </li>
                      </ul>
                  </li>
              </ul>
              <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
      </div>
  </div>
</div>
<!-- Left Sidebar End -->
<!-- ============================================================== -->



<!-- Start right Content here -->
<!-- ============================================================== -->
<div class="content-page">
  <!-- Start content -->
  <div class="content">
      <div class="container">
          <router-outlet></router-outlet>
      </div>
      <!-- container -->
  </div>
  <!-- content -->

  <footer class="footer text-right">
      © 2017-2024. All rights reserved.
  </footer>
</div>
<!-- ============================================================== -->
<!-- End Right content here -->
<!-- ============================================================== -->