import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
//import { RequestMethod } from '@angular/http';
import { MessageService } from '../shared/message.service';
import { AuthenticationService } from './authService';
//import { AppComponent } from '../app.component';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private messageService: MessageService,
        private authService: AuthenticationService,
        @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Check if logged in
        // TODO: Change to user store
        if (!localStorage.getItem("access_token")) {
            this.router.navigate(['/']);
            return;
        }

        const token = localStorage.getItem('access_token');
        let clone: HttpRequest<any>;
        if (token) {
            clone = request.clone({
                setHeaders: {
                    Accept: `application/json`,
                    'Content-Type': `application/json`,
                    Authorization: `Bearer ${token}`
                }
            });
        } else {
            clone = request.clone({
                setHeaders: {
                    Accept: `application/json`,
                    'Content-Type': `application/json`
                }
            });
        }

        return next.handle(clone).pipe(tap((event: HttpEvent<any>) => {
        }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                    this.authService.logout();
                    this.router.navigate(['/']);
                } else if (error.status === 403 && request.method === "GET") {
                    this.router.navigate(["/start"]);
                }

                if (error.status === 403) {
                    this.messageService.setError("You dont have permission to do this.")
                } else if (error.status) {
                    let errMsg: string;

                    errMsg = error.error ? error.error : error.message;

                    console.log(error);
                    this.messageService.setError(errMsg)
                }

            }
        }));
        //return next.handle(this.addAuthHeaderToAllowedOrigins(request));
    }

    // private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
    //     let req = request;
    //     const allowedOrigins = ['http://localhost']; //TODO: Uncomment after setting the correct origins
    //     if (!!allowedOrigins.find(origin => request.url.includes(origin))) {
    //       const authToken = this._oktaAuth.getAccessToken();
    //       req = request.clone({ 
    //           setHeaders: { 
    //             Accept: `application/json`,
    //             'Content-Type': `application/json`,  
    //             'Authorization': `Bearer ${authToken}` 
    //             } 
    //         });
    //     }
    
    //     return req;
    //   }
}