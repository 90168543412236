import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../shared/login.service";
import { AuthenticationService } from "../auth/authService";
import { MessageService } from "../shared/message.service";
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';


declare var $: any;

@Component({
  templateUrl: "./login.component.html",
  providers: [LoginService]
})
export class LoginComponent {
  email = "";
  //p_a_s_s_word = "";
  newEmail = "";
  //newPassword = "";
  //confirmPassword = "";
  forgottenEmail = "";
  isError = false;
  newAccountAlert = {
    class: "",
    message: ""
  };
  loginAlert = {
    class: "",
    message: ""
  };

  constructor(
    private router: Router,
    private LoginService: LoginService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {}

  // login() {
  //   //if (this.isLoginError()) return;
  //   return new Promise((resolve, reject) => {
  //     let subscription = this.LoginService.authenticate(
  //       this.email,
  //       this.password
  //     ).subscribe({
  //       next: (res) => {
  //         //console.log("Login response", res);
  //         this.authenticationService.login(res);
  //         subscription.unsubscribe();
  //         resolve("Login successful");
  //       },
  //       error: err => {
  //         //console.log("save error", err);
  //         if (err.status === 404) {
  //           reject("Invalid login credentials");
  //         } else if (err.status === 401) {
  //           reject("Invalid login credentials");
  //         } else if (err.status === 406) {
  //           reject("Too many failed login attempts. Please try again in 15 minutes.");
  //         }else {
  //           reject("Bad request");
  //         }
  //       }
  //   });
  //   }).then(
  //     res => {
  //       this.clearErrorStatus(this.loginAlert);
  //       this.clearErrorStatus(this.newAccountAlert);
  //       this.router.navigate(["start"]);
  //     },
  //     err => this.alertUser(true, err)
  //   );
  // }

  public async login() : Promise<void> {
    await this._oktaAuth.signInWithRedirect().then(
      _ => this.router.navigate(["/start"])   
    );
  }

  createAccount() {
    // if (this.isNewAccountInfoValid()) return;

    // return new Promise((resolve, reject) => {
    //   let subscription = this.LoginService.createAccount(
    //     this.newEmail,
    //     this.newPassword
    //   ).subscribe({
    //     next: res => {
    //       //console.log("NewAccount response", res);
    //       subscription.unsubscribe();
    //       resolve("New Account was created");
    //     },
    //     error: err => {
    //       //console.log("save error", err);
    //       if (err.status === 409) {
    //         reject("Email already exists");
    //       } else if (err.status === 500) {
    //         reject("Account was not created");
    //       } else {
    //         reject("Bad request");
    //       }
    //     }
    // });
    // }).then(
    //   res => {
    //     this.email = this.newEmail;
    //     this.p_a_s_s_word = this.newPassword;
    //     this.clearNewAccountInfo();
    //     this.alertUser(false, res);
    //     this.clearErrorStatus(this.newAccountAlert);
    //     $("#createAccountModal").modal("hide");
    //   },
    //   err => this.alertUser(true, err)
    // );
  }

  // clearNewAccountInfo() {
  //   this.newEmail = "";
  //   this.newPassword = "";
  //   this.confirmPassword = "";
  // }

  clearErrorStatus(errorObject) {
    errorObject.class = "";
    errorObject.message = "";
  }

  // isLoginError() {
  //   if (!this.email.length) {
  //     this.alertUser(true, "Please enter an email!");
  //     return true;
  //   } else if (!this.password.length) {
  //     this.alertUser(true, "Please enter a password!");
  //     return true;
  //   }

  //   return false;
  // }

  validateEmail() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.newEmail).toLowerCase());
  }

  // passwordIsComplex(){
  //   if (this.newPassword.length < 8) { //Password should contain at least 8 characters
  //     this.alertUser(
  //       true,
  //       "Password is shorter than 8 characters!"
  //     );
  //     return false;
  //   }else if(!/[A-Z]/.test(this.newPassword)){
  //     this.alertUser(
  //       true,
  //       "Password must contain at least one upper-case character!"
  //     );
  //     return false;
  //   }else if(!/[0-9]/.test(this.newPassword)){
  //     this.alertUser(
  //       true,
  //       "Password must contain at least one number!"
  //     );
  //     return false;
  //   }
  //   else if(!/[^a-zA-Z0-9]/.test(this.newPassword)){
  //     this.alertUser(
  //       true,
  //       "Password must contain at least one special character!"
  //     );
  //     return false;
  //   }
  //   return true;
  // }

  // isNewAccountInfoValid() {
  //   if (!this.newEmail.length) {
  //     this.alertUser(
  //       true,
  //       "Please enter an email!"
  //     );
  //     return true;
  //   } else if (!this.validateEmail()) {
  //     this.alertUser(
  //       true,
  //       "Please enter a correct email"
  //     );
  //     return true;
  //   } else if (!this.newPassword.length || !this.confirmPassword) {
  //     this.alertUser(
  //       true,
  //       "Please enter a password!"
  //     );
  //     return true;
  //   }else if (!this.passwordIsComplex()){
  //     return true;
  //   }else if (this.newPassword !== this.confirmPassword) {
  //     this.alertUser(
  //       true,
  //       "Passwords dont match!"
  //     );
  //     return true;
  //   } 
  //   return false;
  // }

  alertUser(error, message) {
    if (error) {
      this.messageService.setError(message);
    } else {
      this.messageService.setMessage(message);
    }
  }

  resetPassword() {
    let subscription = this.LoginService.forgotPasswordRequest(
      this.forgottenEmail
    ).subscribe(res => {      
      $("#resetPasswordModal").modal("hide");
      this.messageService.setMessage(res.body.text());
      subscription.unsubscribe();
    }, err => {
      this.messageService.setError(err.text());
    });
  }

  async ngOnInit(): Promise<void> {
    await this._oktaAuth.signInWithRedirect().then(
      _ => this.router.navigate(["/start"])    
    );
  }
}
