import { DatePipe } from "@angular/common";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from './app.component';
// import { JsonpModule, HttpModule } from "@angular/http";
// import { JsonpModule } from "@angular/http";
import { FormsModule } from "@angular/forms";
import { enableProdMode } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginComponent } from "./login/login.component";
import { StartComponent } from "./start/start.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ResetPassword } from "./resetpassword/resetpassword.component";
import { SensorComponent } from "./sensor/sensor.component";
import { PageNotFoundComponent } from "./pagenotfound/page-not-found.component";
import { CapitalizePipe } from "./shared/capitalize.pipe";
import { KeyValuePipe } from "./shared/key-value.pipe";
import { RoundPipe } from "./shared/round.pipe";
import { FormatEvaluationPipe } from "./shared/formatevaluation.pipe";
import { RequestInterceptor } from "./auth/request.interceptor";
import { AuthenticationService } from "./auth/authService";
import { MessageService } from "./shared/message.service";

import { ToastrModule } from "ngx-toastr";

import { CirclifulDirective } from "./directives/circliful.directive";
import { Map } from "./map/map";

import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

enableProdMode();

const oktaAuth = new OktaAuth({
  issuer: 'https://dev-04521996.okta.com/oauth2/default',
  clientId: '0oa52ks38tLspkrnD5d7',
  redirectUri: window.location.origin + '/login/callback',
});

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // HttpModule,
    HttpClientModule,
    // JsonpModule,
    FormsModule,
    ToastrModule.forRoot(),
    OktaAuthModule
  ],
  declarations: [
    LoginComponent,
    AppComponent,
    StartComponent,
    DashboardComponent,
    SensorComponent,
    ResetPassword,
    PageNotFoundComponent,
    CapitalizePipe,
    KeyValuePipe,
    RoundPipe,
    FormatEvaluationPipe,
    CirclifulDirective
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    RoundPipe,
    FormatEvaluationPipe,
    Map,
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    AuthenticationService,
    MessageService
  ]
})
export class AppModule { }
