import { NgModule }     from '@angular/core';
import { RouterModule } from '@angular/router';

import { StartComponent } from './start/start.component';
import { DashboardComponent }  from './dashboard/dashboard.component';
import { SensorComponent } from './sensor/sensor.component';
import { LoginComponent } from './login/login.component';
import { ResetPassword } from './resetpassword/resetpassword.component';
import { PageNotFoundComponent }    from './pagenotfound/page-not-found.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '', component: LoginComponent},
      { path: 'start', component: StartComponent, canActivate: [OktaAuthGuard] },
      // {
      //   path: '',
      //   loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [OktaAuthGuard]
      // },
      { path: 'dashboard/:project/:sensor', redirectTo: 'sensorview/:project/:sensor', pathMatch: 'full'},//TODO:Test if other routes protected      
      { path: 'sensorview/:project/:sensor', component: SensorComponent },    
      { path: 'sensorview/:project', redirectTo: 'dashboard/:project', pathMatch: 'full' },        
      { path: 'dashboard', redirectTo: '/start',  pathMatch: 'full'},
      { path: 'dashboard/:project', component: DashboardComponent },
      { path: 'user/reset/:token', component: ResetPassword},//TODO:Remove unnecessary routes
      { path: 'login/callback', component: OktaCallbackComponent },
      { path: '**', component: PageNotFoundComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {}
