import { Injectable } from '@angular/core';
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
//import { Headers, Response, RequestOptions } from '@angular/http';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';


import { DataFormats } from './dataformats';

@Injectable()
export class ProjectService {

    constructor(private http: HttpClient) { }

    private dbUrl = '/' //Url to *dbserver*

    getGroups(): Promise<any[]> {
        return lastValueFrom(this.http.get<any[]>(this.dbUrl + 'groups'))
            //.toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    getUsersForGroup(groupName): Promise<any[]> {
        return lastValueFrom(this.http.get<any[]>(this.dbUrl + 'group/members?groupName='+groupName))
            //.toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    addUserToGroup(group: string, email: string): Observable<HttpResponse<any>> {
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group/members/add?groupName=' + group + '&email=' + email, {});
    }

    removeUserFromGroup(group: string, email: string): Observable<HttpResponse<any>> {
        return this.http.delete<HttpResponse<any>>(this.dbUrl + 'group/members?groupName=' + group + '&email=' + email);
    }

    setEmailAlerts(groupName: string, email: string, emailAlerts: boolean){
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group/members/emailalerts', {groupName, email, emailAlerts});
    }

    setWriteAccess(groupName: string, email: string, writeAccess: boolean): Observable<HttpResponse<any>> {
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group/access/set', {groupName, email, writeAccess});
    }

    refreshGroupData(group: any): Observable<HttpResponse<any>>{
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group/data', group);
    }

    setGroupNickname(group: string, nickname: string): Observable<HttpResponse<any>> {
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group/nickname?group=' + group + '&nickname=' + nickname, {});
    }

    createNewGroup(name: string, nickname: string): Observable<HttpResponse<any>> {
        let group = {
            "groupName": name,
            "groupNickname": nickname
        }
        return this.http.post<HttpResponse<any>>(this.dbUrl + 'group', group);
    }

    deleteGroup(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<HttpResponse<any>>(this.dbUrl + 'group?_id=' + id);
    }


    getGroupDataFormats(group: string): Promise<any> {
        return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'group/dataformats?groupName=' + group))
            //.toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    addGroupDataFormat(group: string, dataFormat: DataFormats): Observable<HttpResponse<any>> {
        return this.http.post<HttpResponse<any>>(this.dbUrl + 'group/dataformat?groupName=' + group, dataFormat);
    }

    updateGroupDataFormat(dataFormat: DataFormats): Observable<HttpResponse<any>> {
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group/dataformat', dataFormat);
    }

    deleteGroupDataFormat(dataFormat: DataFormats): Observable<HttpResponse<any>> {
        return this.http.delete<HttpResponse<any>>(this.dbUrl + 'group/dataformat?_id=' + dataFormat._id);
    }

    updateGroup(group: any): Observable<HttpResponse<any>> {
        return this.http.put<HttpResponse<any>>(this.dbUrl + 'group', group);
    }

    getAPIs(currentSensor: string, currentProject: string): Promise<any> {
        return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'api/apis?sensorName=' + currentSensor + '&sensorType=' + currentProject))
            //.toPromise()
            .then(this.extractData)
            .catch(this.handleError);
    }

    triggerAPI(routing: string, currentSensor: string, currentProject: string, bodyToPass: {}): Observable<HttpResponse<any>> {
        //console.log('routing');
        return this.http.post<HttpResponse<any>>(this.dbUrl + 'api/' + routing + '?sensorName=' + currentSensor + '&sensorType=' + currentProject, bodyToPass)
    }


    private extractData(res: HttpResponse<any> | any) {
        return res;
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof HttpResponse) {
            //const body = error.json() || '';
            const err = error.body.error || JSON.stringify(error);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        // console.error("Error with http request:");
        // console.error(errMsg);        
        return {};
    }
}
