import { Injectable, Output, EventEmitter } from "@angular/core";
//import { Http, Headers, Response, RequestOptions } from "@angular/http";
//import { Headers, Response, RequestOptions } from "@angular/http";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class LoginService {
  //constructor(private http: Http) {}
  constructor(private http: HttpClient) {}
  @Output() isLoggedIn: EventEmitter<any> = new EventEmitter();

  private dbUrl = "/"; //Url to *dbserver*

  // authenticate(email, password): Observable<HttpResponse<any>> {
  //   const authenticationInfo = {
  //     email,
  //     password
  //   };
  //   return this.http.post<HttpResponse<any>>(this.dbUrl + "user/login", authenticationInfo);
  // }

  // createAccount(email, password): Observable<HttpResponse<any>> {
  //   const accountInfo = {
  //     email,
  //     password
  //   };
  //   return this.http.post<HttpResponse<any>>(this.dbUrl + "user", accountInfo);
  // }

  forgotPasswordRequest(email): Observable<HttpResponse<any>> {
    const emailInfo = {
      email
    };
    return this.http.post<HttpResponse<any>>(this.dbUrl + "user/forgot_password", emailInfo);
  }

  // resetPasword(token, password){
  //   const newAccountInfo = {
  //     token,
  //     password
  //   };

  //   return this.http.post(this.dbUrl + "user/reset_password", newAccountInfo);
  // }


  private extractData(res: HttpResponse<any>) {
    //console.log("res group", res);

    //let reset = res.json();
    return res;
  }

  private handleError(error: HttpResponse<any> | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpResponse) {
      //const body = error.json() || "";
      const err = error.body.error || JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    // console.error("Error with http request:");
    // console.error(errMsg);
    return {};
  }
}
