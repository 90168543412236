import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from '../shared/message.service';
import { ActivatedRoute, Params } from '@angular/router';
import { LoginService } from "../shared/login.service";

declare var $: any;

@Component({
  templateUrl: "./resetpassword.component.html",
  providers: [LoginService]
})
export class ResetPassword {
  //newPassword = "";
  //confirmPassword = "";
  isError = false;
  token="";

  constructor(private router: Router, 
    private messageService: MessageService,
    private route: ActivatedRoute,
    private loginService: LoginService) {

  }

  resetPassword(){
    // if (!this.checkPassword()) return;

    // let subscription = this.loginService.resetPasword(
    //     this.token,
    //     this.newPassword
    //   ).subscribe(res => {
    //     //this.messageService.setMessage(res.text());
    //     this.router.navigateByUrl("/");
    //     subscription.unsubscribe();
    //   }, err => {
    //     this.messageService.setError(err.text());
    //   });
  }

  // checkPassword(){
  //   if (!this.newPassword.length){
  //     this.alertUser(
  //       true,
  //       "Please enter a password!"
  //     );
  //       return false;
  //   }
  //   else if (!this.passwordIsComplex()){
  //     return false;
  //   }
  //   else if (this.newPassword !== this.confirmPassword){
  //     this.alertUser(
  //       true,
  //       "Passwords dont match!"
  //     );
  //       return false;
  //   }
  //   return true;
  // }

  // passwordIsComplex(){
  //   if (this.newPassword.length < 8) { //Password should contain at least 8 characters
  //     this.alertUser(
  //       true,
  //       "Password is shorter than 8 characters!"
  //     );
  //     return false;
  //   }else if(!/[A-Z]/.test(this.newPassword)){
  //     this.alertUser(
  //       true,
  //       "Password must contain at least one upper-case character!"
  //     );
  //     return false;
  //   }else if(!/[0-9]/.test(this.newPassword)){
  //     this.alertUser(
  //       true,
  //       "Password must contain at least one number!"
  //     );
  //     return false;
  //   }
  //   else if(!/[^a-zA-Z0-9]/.test(this.newPassword)){
  //     this.alertUser(
  //       true,
  //       "Password must contain at least one special character!"
  //     );
  //     return false;
  //   }
  //   return true;
  // }

  alertUser(error, message) {
    if (error) {
      this.messageService.setError(message);
    } else {
      this.messageService.setMessage(message);
    }
  }

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
        this.token = params['token'];
      });
  }
}
