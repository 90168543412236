<div *ngIf="dataLoaded">
  <div class="col-lg-2"></div>
  <div class="col-lg-12">
    <div class="card-box">
      <h4 class="text-dark header-title m-t-0">Project list {{ hej }}</h4>
      <p class="text-muted m-b-30 font-13">All projects are listed below.</p>

      <div class="table-responsive">
        <table class="table table-actions-bar">
          <thead>
            <tr>
              <th>Project</th>
              <th>Sensors</th>
              <th>Settings</th>
            </tr>
          </thead>
          <tbody id="sensor-list">
            <tr *ngFor="let group of groups" class="list-link">
              <td (click)="goToDashboard(group.groupName)">
                <div *ngIf="group.groupNickname">
                  {{ group.groupNickname | capitalize }}
                </div>
                <div *ngIf="!group.groupNickname">
                  {{ group.groupName | capitalize }}
                </div>
              </td>
              <td (click)="goToDashboard(group.groupName)">
                {{ group.numberOfSensors }}
              </td>

              <td class="inline-flex-display">
                <div class="col-xs-2">
                  <i
                    (click)="setSelectedGroup(group)"
                    title="Set project nickname"
                    class="md md-edit cursor-pointer"
                    data-toggle="modal"
                    data-target="#editNickname"
                  ></i>
                </div>
                <div class="col-xs-2">
                  <i
                    (click)="getGroupDataFormats(group)"
                    title="Edit dataformats for project"
                    class="md md-settings cursor-pointer"
                    data-toggle="modal"
                    data-target="#editProject"
                  ></i>
                </div>

                <div class="col-xs-2" *ngIf="!isGroupUpdating[group.groupName]">
                  <i
                    (click)="setSelectedGroup(group)"
                    title="Refresh project"
                    class="ion-refresh cursor-pointer"
                    data-toggle="modal"
                    data-target="#updateProject"
                  ></i>
                </div>
                <div class="col-xs-2" *ngIf="isGroupUpdating[group.groupName]">
                  <i class="ion-refresh ion-spin"></i>
                </div>

                <div class="col-xs-2">
                  <i
                    (click)="setSelectedGroup(group)"
                    title="Delete project"
                    *ngIf="hasRemoveAccess(group)"
                    class="ion-trash-b cursor-pointer"
                    data-toggle="modal"
                    data-target="#deleteProject"
                  ></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn bg-white dropdown-toggle waves-effect waves-light"
            data-toggle="modal"
            data-target="#addProject"
          >
            Add new project <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end col -->
  <div class="col-lg-2"></div>

  <!-- Edit Nickname -->
  <div
    *ngIf="selectedGroup != undefined"
    id="editNickname"
    class="modal fade in"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
          <h4 class="modal-title" id="myModalLabel">
            Change group nickname for {{ selectedGroup.groupName }}
          </h4>
        </div>
        <div class="modal-body">
          <form
            (ngSubmit)="setGroupNickname(nickname)"
            #setGroupNicknameForm="ngForm"
          >
            <div class="form-group">
              <label for="nickname">Nickname</label>
              <input
                class="form-control"
                id="nicknameInput"
                name="nickname"
                required
                [(ngModel)]="nickname"
                placeholder="Nickname"
              />
            </div>
            <button
              type="button"
              class="btn btn-default waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-success waves-effect waves-light"
              [disabled]="!setGroupNicknameForm.form.valid"
            >
              Save nickname
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit dataformats -->
  <div
    *ngIf="selectedGroup != undefined"
    id="editProject"
    class="modal fade in"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
        <h4 class="modal-title" id="myModalLabel">
          {{ selectedGroup.groupName }}
        </h4>
        <div class="modal-body">
          <h3>General project settings</h3>
          <div class="divider divider-height"></div>
          <div class="table-responsive">
            <table class="table table-actions-bar">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Write access</th>
                  <th>Email Alerts</th>
                  <th>Remove user from project</th>
                </tr>
              </thead>
              <!-- <th>Type</th> -->
              <tbody id="user-list">
                <tr
                  *ngFor="let user of usersForSelectedGroup"
                  class="list-link"
                >
                  <td>
                    {{ user.email }}
                    <div
                      class="you-hint"
                      *ngIf="currentUser.email === user.email"
                    >
                      You
                    </div>
                    <div
                      class="you-hint"
                      *ngIf="selectedGroup.groupOwner === user.email"
                    >
                      Owner
                    </div>
                  </td>
                  <td align="center">
                    <input
                      class=""
                      id="{{user.email}}--writeAccess"
                      [(ngModel)]="user.writeAccess"
                      [disabled]="!hasWriteAccess(user)"
                      (click)="updateWriteAccess(selectedGroup.groupName, user)"
                      type="checkbox"
                    />
                  </td>
                  <td align="center">
                    <input
                      class=""
                      id="{{user.email}}--alert"
                      [disabled]="currentUser.email !== user.email"
                      [(ngModel)]="user.emailAlerts"
                      (click)="updateEmailAlerts(selectedGroup.groupName, user)"
                      type="checkbox"
                    />
                  </td>
                  <td
                    align="center"
                    *ngIf="hasUserRemoveAccess(user)"
                    (click)="removeUserFromProject(user)"
                  >
                    <i class="ion-trash-b cursor-pointer"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <label class="block-display">Add user to project</label>
          <form (ngSubmit)="addUserToProject()" #dataFormatsFormUser="ngForm">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    id="newUserEmail"
                    required
                    [(ngModel)]="newUserEmail"
                    name="newUserEmail"
                    [disabled]="!currentUser.hasAccessForSelectedGroup"
                  />
                </div>
              </div>
              <div class="col-lg-2">
                <button
                  type="submit"
                  class="btn btn-success waves-effect waves-light"
                  [disabled]="!currentUser.hasAccessForSelectedGroup"
                >
                  Add user
                </button>
              </div>
            </div>
          </form>
          <div class="divider"></div>
          <label class="block-display"> Location service provider:</label>
          <div class="btn-group text-center dropdown">
            <button
              id="locationServiceProvider"
              type="button"
              title="Selected location service provider"
              class="btn btn-default dropdown-toggle waves-effect waves-light"
              data-toggle="dropdown"
              aria-expanded="false"
              [disabled]="!currentUser.hasAccessForSelectedGroup"
            >
              <span class="m-l-5">
                {{ currentServiceLocationProvider }}
                <i class="fa ion-chevron-down"></i>
              </span>
            </button>
            <ul class="dropdown-menu drop-menu-right" role="menu">
              <li
                *ngFor="let provider of locationServiceProviders"
                (click)="callChangeLocationServiceProvider(provider)"
              >
                <a id="{{ provider }}"> {{ provider }}</a>
              </li>
            </ul>
          </div>
          <div class="divider"></div>

          <div class="data-formats-margin-top">
            <div class="row relative-position">
              <h3 class="m-l-5 inline-display">Project dataformats</h3>
              <div class="pull-right absolute-position">
                <label for="header">Use headers</label>
                <input
                  type="checkbox"
                  class=""
                  id="header"
                  (click)="setIsUsingHeaders()"
                  [(ngModel)]="selectedGroup.usingHeaders"
                  name="header"
                  [disabled]="!currentUser.hasAccessForSelectedGroup"
                />
              </div>
            </div>
            <div class="divider height-three"></div>
            <div class="btn-group text-center">
              <button
                id="dataformats"
                type="button"
                title="Selected datatype"
                class="btn btn-default dropdown-toggle waves-effect waves-light"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="m-l-5">
                  {{ currentDataFormat.dataType | capitalize }}
                  <i class="fa ion-chevron-down"></i>
                </span>
              </button>
              <ul class="dropdown-menu drop-menu-right" role="menu">
                <li
                  *ngFor="let dataformat of currentDataFormats"
                  (click)="callChangeDataFormat(dataformat)"
                >
                  <a id="{{ dataformat.name }}">
                    {{ dataformat.dataType | capitalize }}</a
                  >
                </li>
                <li class="divider"></li>
                <li (click)="callAddDataFormat(dataformat)">
                  <a>Add new dataformat</a>
                </li>
              </ul>
            </div>

            <div class="btn-group pull-right">
              <i
                class="md-2x ion-refresh cursor-pointer text-pink"
                data-toggle="modal"
                title="Refresh project"
                data-target="#updateProject"
              ></i>
              <i
                class="md-2x md-info text-primary cursor-pointer"
                title="Information"
                data-toggle="popover"
                data-placement="center"
                data-html="true"
                data-content="
              <div style='width: 18em;'>
                  <h4>Use headers?</h4>
                  If your type of sensors are able to send headers in the data string, this is the prefered method. If not, data will be read from the defined order that is set.

                  <h4>indoor-pos (only available with headers)</h4>
                  <i>indoor-pos</i> is a reserved datatype, used for sending MAC-adresses of nearby routers to Google and get mapdata back. 
                  Data is read as following: </br>|Header|If RSSI is sent and Nr of MAC-adresses (MSB indicates if RSSI is present or not, 7 remaining bits indicates number of MAC-adresses (and potentially RSSI values) sent)| MAC-adress 1 (6 bytes or 12 characters) (|If rssi is sent, 1 byte signed int|)|... | MAC-adress N| 

                  <h4>GNSS</h4>
                  <i>GNSS</i> is also a reserved datatype, used for location data. Data is read as following: </br>|Header |Lat (8 characters)|Long (8 characters)|
				  There is also an alternate version called <i>GNSS_WACC</i> which uses the same format as above but with an additional 4 characters at the end representing horizontal accuracy.

                  <h4>Battery</h4>
                  <i>battery</i> is recommended to define as a datatype, spelled with lower-case characters, as much front-end functionality will not work without it. 
                  
                  <h4>SNR and RSSI</h4>
                  If you wish to display <i>snr</i> and <i>rssi</i> data, add them as dataformats with no header or length.
                  
              </div>              
              "
              ></i>
            </div>
            <form
              #dataFormatsForm="ngForm"
              (ngSubmit)="saveDataformat()"
              class="form-margin-top">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="Datatype">Datatype</label>
                    <input
                      type="text"
                      class="form-control"
                      id="Datatype"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      required
                      [(ngModel)]="currentDataFormat.dataType"
                      name="Datatype"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="unit">Unit</label>
                    <input
                      type="text"
                      class="form-control"
                      id="unit"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      [(ngModel)]="currentDataFormat.unit"
                      name="unit"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6" *ngIf="selectedGroup.usingHeaders">
                  <div class="form-group">
                    <label for="header">Header</label>
                    <input
                      type="text"
                      class="form-control"
                      id="header"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      [(ngModel)]="currentDataFormat.header"
                      name="header"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-6"
                  *ngIf="!selectedGroup.usingHeaders && currentDataFormats != undefined"
                >
                  <div class="form-group">
                    <label for="header">Order</label>
                    <input
                      type="number"
                      class="form-control"
                      id="order"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      [(ngModel)]="indexOfDataFormat"
                      (focusout)="updateDataFormatsOrder()"
                      name="order"
                      min="1"
                      max="{{currentDataFormats.length}}"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="length">Length</label>
                    <input
                      type="number"
                      class="form-control"
                      id="length"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      required
                      [(ngModel)]="currentDataFormat.length"
                      name="length"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <label>Value type</label>
                  <div class="form-group">
                      <input
                        type="radio"
                        (change)="onValueTypeChange()"
                        radiogroup="value-type"
                        name="value-type-radio"
                        value="signed"
                        [(ngModel)]="currentValueType"
                        [attr.disabled]="currentUser.hasAccessForSelectedGroup ? null : false"
                      />
                      Signed
                      <input
                        type="radio"
                        (change)="onValueTypeChange()"
                        radiogroup="value-type"
                        name="value-type-radio"
                        value="unsigned"
                        [(ngModel)]="currentValueType"
                        [attr.disabled]="currentUser.hasAccessForSelectedGroup ? null : false"
                      />
                      Unsigned
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="preValueConv">Value conversion</label>
                    <input
                      type="text"
                      class="form-control"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      id="preValueConv"
                      required
                      [(ngModel)]="currentDataFormat.preValueConv"
                      (ngModelChange)="calculateConversion()"
                      name="preValueConv"
                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label>with x =</label>
                    <input
                      type="text"
                      class="form-control"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      [(ngModel)]="xValue"
                      (ngModelChange)="calculateConversion()"
                      name="xValue"
                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div>
                    <label>equals</label>
                    <div
                      class="form-control"
                      [ngClass]="{ 'btn-danger': conversionFail }"
                    >
                      {{ conversionEquals }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="icon">Icon </label>
                    <select
                      name="icon"
                      class="form-control"
                      id="icon"
                      [(ngModel)]="currentDataFormat.icon"
                      [disabled]="!currentUser.hasAccessForSelectedGroup"
                      name="icon"
                    >
                      <option *ngFor="let icon of selectableIcons">
                        {{ icon }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="text">
                    <label class="block-display">Preview</label>
                    <i
                      *ngIf="currentDataFormat.icon != ''"
                      class="md-3x {{currentDataFormat.icon}} text-primary"
                    ></i>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-success waves-effect waves-light"
                [disabled]="
                  !currentUser.hasAccessForSelectedGroup ||
                  (!dataFormatsForm.form.valid || conversionFail)
                "
              >
                Save dataformat
              </button>
              <button
                type="button"
                class="btn btn-danger waves-effect waves-light"
                [disabled]="!currentUser.hasAccessForSelectedGroup"
                data-toggle="modal"
                data-target="#deleteDataformat"
              >
                Delete dataformat
              </button>
              <!-- (click)="deleteCurrentDataformat()" -->
              <button
                type="button"
                class="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add new project -->
  <div id="addProject" class="modal fade in">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
          <h4 class="modal-title" id="myModalLabel">Create new project</h4>
        </div>
        <div class="modal-body">
          <form (ngSubmit)="createNewProject()" #createProjectForm="ngForm">
            <div class="form-group">
              <label for="name">Project name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                required
                [(ngModel)]="newGroupName"
              />
            </div>
            <div class="form-group">
              <label for="nickname">Project nickname</label>
              <input
                type="text"
                class="form-control"
                id="nickname"
                name="nickname"
                [(ngModel)]="newGroupNickname"
              />
            </div>
            <button
              type="button"
              class="btn btn-default waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-success waves-effect waves-light"
              [disabled]="!createProjectForm.form.valid"
            >
              Create project
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div id="deleteDataformat" class="modal fade in">
    <div class="modal-dialog pull-center">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            Delete dataformat {{ currentDataFormat.dataType }}?
          </h4>
        </div>
        <div class="modal-body">
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-danger waves-effect waves-light"
            data-dismiss="modal"
            (click)="deleteCurrentDataformat()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="selectedGroup != undefined"
    id="deleteProject"
    class="modal fade in"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            Delete project {{ selectedGroup.groupName }}?
          </h4>
        </div>
        <div class="modal-body">
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-danger waves-effect waves-light"
            data-dismiss="modal"
            (click)="deleteProject()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="selectedGroup != undefined"
    id="updateProject"
    class="modal fade in"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            Update project {{ selectedGroup.groupName }}?
          </h4>
          <p class="text-muted m-b-30 font-13">
            This will recalculate all data stored using the currently stored
            dataformats. Note that this will take a lot of time depending on
            amount of sensordata
          </p>
        </div>
        <div class="modal-body">
          <button
            type="button"
            class="btn btn-default waves-effect"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-warning waves-effect waves-light"
            data-dismiss="modal"
            (click)="refreshGroupData()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
