export class DataFormats{
constructor(
    public _id: string,
    public dataType: string,    
    public header: string,
    public length: Number,
    public preValueConv: string = "x",
    public postValueConv: string = "x",
    public icon: string = "md-settings-remote",
    public unit?: string,
    public isSigned: boolean = true, 
){ }
}
// Example datastring: 64000064786cbe00
