import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class MessageService {
  constructor() {}
	public error: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public message: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public warning: BehaviorSubject<string> = new BehaviorSubject<string>('');

	public setError(error){
		this.error.next(error);
	}

	public setMessage(msg){
		this.message.next(msg);
	}

	public setWarning(msg){
		this.warning.next(msg);
	}
}
