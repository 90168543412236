import {Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'formatevaluation'})
export class FormatEvaluationPipe implements PipeTransform {

    transform(value:any) {
        if (value) {
            return (value.charAt(0).toUpperCase() + value.substr(1)).replace("_", " ");
        }
        return value;
    }

}