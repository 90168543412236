import { Inject, Injectable, Output, EventEmitter} from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable, filter, map } from 'rxjs';


@Injectable()
export class AuthenticationService {
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public user: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public obsEmail$!:Observable<string>;
    private token:string;
    private helper = new JwtHelperService();


    constructor(
        private _oktaAuthStateService: OktaAuthStateService,
        @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
        ){
        this.token = localStorage.getItem("access_token");
        if(this.token){
            this.isUserLoggedIn.next(true)
            const user = this.decodeToken();         
            this.user.next({email: user.sub})
        }
    }

    getCurrentUser(){
        return this.decodeToken();
    }

    decodeToken(){
        return this.helper.decodeToken(this.token);
    }


    // login(res): void {
    //     this.setToken(res.token)
    //     this.isUserLoggedIn.next(true);
    //     this.user.next({email: this.decodeToken().email});
    // }

    login(): void {
        this.setToken()
        this.isUserLoggedIn.next(true);
        this.obsEmail$ = this._oktaAuthStateService.authState$.pipe(
            filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
            map((authState: AuthState) => authState.idToken?.claims.email ?? ''));
        this.obsEmail$.subscribe(value => this.user.next({email: value})); 
    }

    public async logout(): Promise<void> {
        await this._oktaAuth.signOut();
        this.isUserLoggedIn.next(false);
        this.user.next({});
        this.token = null;
        localStorage.removeItem("access_token");
    }

    // setToken(token){
    //     localStorage.setItem("access_token",token);
    //     this.token = token;
    // }

    setToken(){
        //const authToken = this._oktaAuth.getAccessToken();//TODO:Does the token change over time?
        localStorage.setItem("access_token",this._oktaAuth.getAccessToken());
        this.token = this._oktaAuth.getAccessToken();
    }

    getToken(){
        return this.token;
    }

}