

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
//import { Location, DatePipe } from '@angular/common';

import { SensorData } from '../shared/sensor-data';
import { SensorService } from '../shared/sensor.service';
import { ProjectService } from '../shared/project.service';
import { RoundPipe } from './../shared/round.pipe';
import { DataFormats } from './../shared/dataformats';
import { Map } from '../map/map';
import { dataTypes } from './../shared/global';
import { AlertService } from '../shared/alert.service';


declare var $: any;
declare var Chartist: any;
declare const google: any;

@Component({
  templateUrl: './dashboard.component.html',
  providers: [SensorService, ProjectService, AlertService]

})

export class DashboardComponent implements OnInit, OnDestroy {

  mode = 'Observable';
  currentProject = "";
  sensorDataArray = [];
  alertArray = [];
  valuesForGroup = [];
  calls = 0;
  lowestBattery = 100;
  chart;
  measurement;
  currentProjectNickname = "";
  groupNicknames = {};
  sortNewestDate: boolean;
  sortAlphabeticFirst: boolean;
  sortLowestBattery: boolean;
  sortUpdated: boolean;
  sortSensor: boolean;
  sortBattery: boolean;
  dataFormats: DataFormats[];
  showMap: boolean = true;
  updateTimer: any;
  screenWidth: number;
  mobileWidhtLessThan: number = 768;
  evaluationTypes = ["greater_than", "less_than"];
  currentAlert: any;
  newAlertAppliesTo: any;
  newAlertIsAllChecked: Boolean;
  newAlertErrorMessage: String;
  alertErrorClass: String;
  isInAlertEditMode: boolean = false;
  statusFlagForSensor = {};


  constructor(
    private sensorService: SensorService,
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private roundPipe: RoundPipe,
    private map: Map
  ) {
    this.newAlertAppliesTo = {};
    this.screenWidth = (window.innerWidth);
  }

  getGroupDataFormats(): void {
    this.valuesForGroup = [];
    this.sensorDataArray = [];
    this.alertArray = [];
    this.projectService.getGroupDataFormats(this.currentProject)
      .then((dataFormats) => {
        this.dataFormats = dataFormats;
        //console.log("DATAFORMATS: ");
        //console.log(dataFormats);
        if (this.dataFormats.length > 0) {
          for (let dataformat of this.dataFormats) {
            if (!dataTypes.exclude.some(type => String(type).toLowerCase() === String(dataformat['dataType']).toLowerCase())) {     // Checks if current datatype is in the 'exclude'-list
              this.valuesForGroup.push(dataformat['dataType']);
            }
          }
          this.valuesForGroup.push('battery');
          this.measurement = this.valuesForGroup[0];
          this.getLatestDataAllSensors();
        } else {
          //console.log("No dataformats found");
          $('#distributed-series').hide();
        }
      });
  }


  getLatestDataAllSensors(): void {
    this.sensorService.getLatestDataAllSensors(this.currentProject).then((sensorData) => {
      //console.log('SENSORDATA:')
      //console.log(sensorData);
      if(!sensorData) return;
      

      if (Object.keys(sensorData).length > 0) {
        let tempDataArray = [];
        //this.sensorDataArray = [];
        //console.log("Sensor data", sensorData);

        for (let name in sensorData) {


          let sensor = new SensorData();
          //console.log("Data: ", sensorData);

          if(!sensorData[name]['sensorData']) continue;
          
          sensor.lastModified = new Date(sensorData[name]['sensorData']['time'] * 1000);
          sensor.sensorName = name;
          sensor.sensorData = sensorData[name]['sensorData'];
          sensor.sensorNickname = sensorData[name]['sensorNickname'];
          sensor.sensorData['battery'] = Number(sensor.sensorData['battery']) < 0 ? 0 : Number(sensor.sensorData['battery']);
          sensor.sensorData['battery'] = sensor.sensorData['battery'] > 100 ? 100 : sensor.sensorData['battery'];
          sensor.triggeredAlerts = this.triggeredAlertsDisplayString(sensorData[name].triggeredAlerts);
          sensor.alertFlag = sensorData[name].alertFlag;



          this.statusFlagForSensor[name] = sensor.alertFlag;
          if (this.lowestBattery > sensor.sensorData['battery']) {
            this.lowestBattery = this.roundPipe.transform(parseInt(sensorData[name]['sensorData']['battery']));
          }
          tempDataArray.push(sensor);

        }
        if (this.sensorDataArray !== tempDataArray){
          //console.log("not same");
          //console.log("Temp", tempDataArray);
          //console.log("Old", this.sensorDataArray);
          this.sensorDataArray = tempDataArray;
        } else {
          //console.log("same ");
          //console.log("Temp", tempDataArray);
          //console.log("Old", this.sensorDataArray);
        }

        //console.log("Status flags", this.statusFlagForSensor);

        // Clear battery before giving it a value to be able to update it
        $('#lowest-battery.circliful-chart').empty().removeData();
        $('#lowest-battery.circliful-chart').attr('data-percent', this.lowestBattery).attr('data-text', this.lowestBattery + "%").circliful();
        $('#distributed-series').show();
        this.initChartist();
      } else {
        //console.log("Error loading sensordata");
        $('#distributed-series').hide();
      }
      //Sort latest data based on the selected sort criteria
      this.setSorts();
    });
  }

  getAlerts() {
    this.alertService.getGroupAlerts(this.currentProject).then(
      (res) => {
        //console.log("ALERTS: ", res);
        this.alertArray = [];
        for (let alert of res.foundAlerts) {
          this.alertArray.push(alert);
        }
      }
    )
  }

  getLatestMapData(): void {
    this.sensorService.getLatestGeoDataForGroup(this.currentProject)
      .then((geoData) => {
        //console.log("MAPDATA");
        //console.log(geoData);

        let locationData = [];
        for (let sensor in geoData) {
          let toPush = geoData[sensor]['geographicData'];
          toPush['sensorName'] = geoData[sensor]['sensorNickname'] == undefined ? sensor : geoData[sensor]['sensorNickname'];
          locationData.push(toPush);
        }
        if (locationData.length > 0) {
          this.showMap = true;
          this.map.init(this.markerFunction, this.markerTitleFunction, locationData);
        }
        else
          this.showMap = false;

      })
      .catch(error => {
        this.showMap = false;
      });
  }

  setSorts() {
    if (this.sortSensor)
    {
      this.sortAlphabeticFirst = !this.sortAlphabeticFirst;
      this.sortListBy("Sensor");
    }
    else if (this.sortUpdated)
    {
      this.sortNewestDate = !this.sortNewestDate;
      this.sortListBy("Updated");
    }
    else if (this.sortBattery)
    {
      this.sortLowestBattery = !this.sortLowestBattery;
      this.sortListBy("Battery");
    }
  }

  triggeredAlertsDisplayString(triggeredAlerts) {
    let triggeredAlertsString = "Triggered alerts:";
    triggeredAlerts.forEach(triggeredAlert => {
      this.alertArray.forEach((alert, index) => {
        if (triggeredAlert === alert._id) triggeredAlertsString += "\n" + alert.alertName
      })
    });
    return triggeredAlertsString
  }

  /**
   * Sorts the list by specified value. If called again with same value it will sort reversed.
   * 
   * @param value is what to sort by
   */
  sortListBy(value: string): void {
    switch (value) {
      case 'Updated': {
        if (this.sortNewestDate) {
          this.sensorDataArray.sort(function (sensor1, sensor2) {
            if (sensor1.lastModified < sensor2.lastModified) {
              return 1;
            } else if (sensor1.lastModified > sensor2.lastModified) {
              return -1;
            } else {
              return 0;
            }
          });
        } else {
          this.sensorDataArray.sort(function (sensor1, sensor2) {
            if (sensor1.lastModified < sensor2.lastModified) {
              return -1;
            } else if (sensor1.lastModified > sensor2.lastModified) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        this.sortNewestDate = !this.sortNewestDate;
        this.sortUpdated = true;
        this.sortSensor = false;
        this.sortBattery = false;
        break;
      }
      case 'Sensor': {
        if (this.sortAlphabeticFirst) {
          this.sensorDataArray.sort(function (sensor1, sensor2) {
            if (sensor1.sensorNickname < sensor2.sensorNickname) {
              return -1;
            } else if (sensor1.sensorNickname > sensor2.sensorNickname) {
              return 1;
            } else {
              return 0;
            }
          });
        } else {
          this.sensorDataArray.sort(function (sensor1, sensor2) {
            if (sensor1.sensorNickname < sensor2.sensorNickname) {
              return 1;
            } else if (sensor1.sensorNickname > sensor2.sensorNickname) {
              return -1;
            } else {
              return 0;
            }
          });
        }
        this.sortAlphabeticFirst = !this.sortAlphabeticFirst;
        this.sortSensor = true;
        this.sortUpdated = false;
        this.sortBattery = false;
        break;
      }
      case 'Battery': {
        if (this.sortLowestBattery) {
          this.sensorDataArray.sort(function (sensor1, sensor2) {
            if (sensor1.sensorData.battery < sensor2.sensorData.battery) {
              return 1;
            } else if (sensor1.sensorData.battery > sensor2.sensorData.battery) {
              return -1;
            } else {
              return 0;
            }
          });
        } else {
          this.sensorDataArray.sort(function (sensor1, sensor2) {
            if (sensor1.sensorData.battery < sensor2.sensorData.battery) {
              return -1;
            } else if (sensor1.sensorData.battery > sensor2.sensorData.battery) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        this.sortLowestBattery = !this.sortLowestBattery;
        this.sortBattery = true;
        this.sortUpdated = false;
        this.sortSensor = false;
        break;
      }
    }
  }

  initChartist(): void {
    var series = [];
    var names = [];
    for (var i = 0; i < this.sensorDataArray.length; i++) {
      if (this.sensorDataArray[i].sensorData[this.measurement] != undefined) {
        series.push({
          value: this.sensorDataArray[i].sensorData[this.measurement],
          meta: this.sensorDataArray[i].sensorNickname + ', ' + this.datePipe.transform(this.sensorDataArray[i].lastModified, 'EEE MMM dd yyyy HH:mm:ss')        //Also store date in tooltip. String decides format, checkout datepipe to customize. 
        });
      }
      else {
        series.push(NaN);  // Push NaN if value is not defined so graph works even if it wont get a value
      }
      if (this.sensorDataArray[i].sensorNickname != "" && this.sensorDataArray[i].sensorNickname != undefined) {
        names.push(this.screenWidth > this.mobileWidhtLessThan ? this.sensorDataArray[i].sensorNickname : this.sensorDataArray[i].sensorNickname.slice(-3));
      } else {
        names.push(this.screenWidth > this.mobileWidhtLessThan ? this.sensorDataArray[i].sensorName : this.sensorDataArray[i].sensorName.slice(-3));
      }
    }

    var data = {
      labels: names,
      series: series
    };

    this.chart = new Chartist.Bar('#distributed-series', data, {
      distributeSeries: true,
      plugins: [
        Chartist.plugins.tooltip()
      ],
      axisX: {
        offset: 70
      },
      axisY: {
        offset: 70
      }
    });
  


  }

  initNewAlert() {
    this.currentAlert = {
      alertName: "",
      appliesTo: [],
      dataType: this.valuesForGroup[0],
      evaluation: this.evaluationTypes[0],
      triggerValue: 0,
      groupName: this.currentProject,
    };
    this.newAlertIsAllChecked = false;
    this.sensorDataArray.forEach((sensor) => {
      this.newAlertAppliesTo[sensor.sensorName] = false;
    });
    this.isInAlertEditMode = false;
  }

  showDropdown(event) {
    event.stopPropagation();
  }

  convertToDisplayName(appliesToString) {
    return appliesToString.length === this.sensorDataArray.length ? 'All project sensors' : appliesToString.toString().split(',').join(', ');
  }

  toggleAllCheckBox(event) {
    const checked = event.target.checked;
    if (checked) {
      this.sensorDataArray.forEach((sensor) => {
        this.newAlertAppliesTo[sensor.sensorName] = true;
      });
    } else {
      this.sensorDataArray.forEach((sensor) => {
        this.newAlertAppliesTo[sensor.sensorName] = false;
      });
    }
  }

  toggleCheckBox(event, sensor) {
    let allChecked = event.target.checked;
    this.newAlertAppliesTo[sensor.sensorName] = !this.newAlertAppliesTo[sensor.sensorName];

    this.sensorDataArray.forEach((sensor) => {
      if (this.newAlertAppliesTo[sensor.sensorName] === false) {
        allChecked = false;
      }
    });
    this.newAlertIsAllChecked = allChecked;
  }

  setNewAlertDatatype(datatype) {
    this.currentAlert.dataType = datatype;
    //console.log("Datatype", datatype);
  }

  setNewAlertEvaluation(evaluation) {
    this.currentAlert.evaluation = evaluation;
  }

  saveAlert() {

    this.currentAlert.appliesTo = this.getSelectedSensors().toString();
    if (!this.validateNewAlert(this.currentAlert)) {
      this.alertErrorClass = "alert alert-danger"
      //console.log("ERROR");
      return;
    }

    if (this.currentAlert._id) {
      this.updateAlert();
      return;
    }

    let subscription = this.alertService.createAlert(
      this.currentAlert
    ).subscribe((res) => {
      //console.log("Sub response", res);
      this.getAlerts();
      $("#alertModal").modal("hide");
      subscription.unsubscribe();
    },
      error => {
        //console.log("save error", error);
        if (error.status == 409) {
          this.alertErrorClass = "alert alert-danger";
          this.newAlertErrorMessage = `Could not create alert. An alert with name ${this.currentAlert.alertName} already exists.`
        } else {
          this.alertErrorClass = "alert alert-danger";
          this.newAlertErrorMessage = "Could not create alert: " + error.status;
        }
      });



  }

  getSelectedSensors() {
    const sensorNames = Object.keys(this.newAlertAppliesTo);
    return sensorNames.filter((element) => {
      if (this.newAlertAppliesTo[element]) return element;
    })
  }


  validateNewAlert({ alertName, appliesTo, triggerValue }) {

    if (!alertName) {
      this.newAlertErrorMessage = "Alert name cannot be empty."
      return false;
    }

    if (!appliesTo.length) {
      this.newAlertErrorMessage = "List of sensors cannot be empty."
      return false;
    }

    if (isNaN(triggerValue) || !isFinite(triggerValue)) {
      this.newAlertErrorMessage = "Trigger value must be a valid number."
      return false;
    }
    this.alertErrorClass = "";
    this.newAlertErrorMessage = "";
    return true;
  }


  callChangeValue(value): void {
    this.measurement = value;
    this.updateChartist();
  }

  updateChartist(): void {
    var series = [];
    var names = [];
    for (var i = 0; i < this.sensorDataArray.length; i++) {
      if (this.sensorDataArray[i].sensorData[this.measurement] != undefined) {
        series.push({
          value: this.sensorDataArray[i].sensorData[this.measurement],
          meta: this.sensorDataArray[i].sensorNickname + ', ' + this.datePipe.transform(this.sensorDataArray[i].lastModified, 'EEE MMM dd yyyy HH:mm:ss')        //Also store date in tooltip. String decides format, checkout datepipe to customize. 
        });
      }
      else {
        series.push(NaN);   // Push NaN if value is not defined so graph works even if it wont get a value
      }
      if (this.sensorDataArray[i].sensorNickname != "" && this.sensorDataArray[i].sensorNickname != undefined) {
        names.push(this.screenWidth > this.mobileWidhtLessThan ? this.sensorDataArray[i].sensorNickname : this.sensorDataArray[i].sensorNickname.slice(-3));
      } else {
        names.push(this.screenWidth > this.mobileWidhtLessThan ? this.sensorDataArray[i].sensorName : this.sensorDataArray[i].sensorName.slice(-3));
      }
    }

    var data = {
      labels: names,
      series: series
    };

    this.chart.update(data);
  }

  // Markers for map
  markerFunction(locationData, datePipe) {
    let time = new Date(Number(locationData['time']) * 1000);
    let timeString = datePipe.transform(time, 'EEE d MMM yyyy HH:mm:ss');

    if (locationData['radius'] == undefined || locationData['radius'] === null)
      return '<h4>' + locationData['sensorName'] + '</h4>' + '<p>' + timeString + '</p>';
    else
      return '<h4>' + locationData['sensorName'] + '</h4>' + '<p>' + timeString + '<br> Accuracy: ' + locationData['radius'] + ' meters </p>';
  }

  // Markers for map
  markerTitleFunction(locationData, datePipe) {
    let time = new Date(Number(locationData['time']) * 1000);
    let timeString = datePipe.transform(time, 'EEE d MMM yyyy HH:mm:ss');
    return locationData['sensorName'] + ' ' + timeString;
  }

  goToSensor(project, sensor): void {
    let link = ['/sensorview', project, sensor];
    this.router.navigate(link);
  }

  goToHome(): void {
    let link = ['/'];
    this.router.navigate(link);
  }

  pauseInterval(): void {
    clearInterval(this.updateTimer);

  }

  continueInterval(): void {
    this.updateTimer = setInterval(function () {
      //console.log("Updating dashboard of group " + this.currentProject);
      this.getLatestDataAllSensors();
      this.getLatestMapData();
    }.bind(this), 30000);
  }

  deleteAlert(alert) {
    //console.log("Alert for deletion", alert);
    this.alertService.deleteAlert(alert).subscribe(
      () => {
        this.getAlerts();
      },
      (error) => {
        //console.log("Delete alert error:", error);
      }
    )
  }

  updateAlert() {
    let subscription = this.alertService.editAlert(this.currentAlert).subscribe(
      (res) => {
        this.getAlerts();
        $("#alertModal").modal("hide");
        subscription.unsubscribe();
        //console.log("Edit response", res);
      },
      (err) => {
        //console.log("Error while editing alert: ", err);
      }
    )
  }

  initEditAlert(alertToUpdate) {

    this.newAlertAppliesTo = {};

    this.currentAlert = {
      alertName: alertToUpdate.alertName,
      dataType: alertToUpdate.dataType,
      evaluation: alertToUpdate.evaluation,
      appliesTo: alertToUpdate.appliesTo,
      triggerValue: alertToUpdate.triggerValue,
      groupName: this.currentProject,
      _id: alertToUpdate._id
    }
    //console.log(alertToUpdate.appliesTo);

    this.newAlertIsAllChecked = this.currentAlert.appliesTo.length === this.sensorDataArray.length;
    //console.log(this.currentAlert.appliesTo);

    this.sensorDataArray.forEach((sensor) => {
      this.newAlertAppliesTo[sensor.sensorName] = (this.currentAlert.appliesTo.includes(sensor.sensorName));
    });
  }

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      this.currentProject = params['project'];
    });

    // Init circliful graph
    $('#lowest-battery.circliful-chart').empty().removeData();
    $('#lowest-battery.circliful-chart').attr('data-percent', 0).attr('data-text', "0%").circliful();
    $('#distributed-series').show();

    this.currentAlert = {};

    this.sortNewestDate = true;
    this.sortAlphabeticFirst = true;
    this.sortLowestBattery = true;

    this.sortUpdated = false;
    this.sortSensor  = false;
    this.sortBattery = false;

    // Each time the route link is changed, call this method to update data.
    this.route.params.subscribe(val => {
      this.lowestBattery = 100;
      clearInterval(this.updateTimer);
      this.getGroupDataFormats();
      this.getAlerts();
      this.showMap = true;
      this.map.loadMap().then(() => {
       this.map.init(this.markerFunction, this.markerFunction, [{ lat: 58.416279, long: 15.560577, sensorName: "NotASensor", time: 1511522502 }]);
       this.getLatestMapData();
      });
      this.updateTimer = setInterval(function () {
        //console.log("Updating dashboard of group " + this.currentProject);
        this.getLatestDataAllSensors();
        this.getLatestMapData();
      }.bind(this), 30000);
    })
  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    //console.log("On destroy: clear interval for dashboard");
    clearInterval(this.updateTimer);
  }
}