<div class="row">
    <div class="col-lg-12">
        <!-- Change nickname -->
        <div class="btn-group pull-right">
            <i class="md md-edit cursor-pointer" title="Change sensor nickname" data-toggle="modal" data-target="#editNickname"></i>
            <i class="md md-refresh cursor-pointer" title="Update data" (click)="getLatestSensorData(); getLatestMapData()"></i>
            <i class="md md-settings cursor-pointer" title="Trigger APIs" data-toggle="modal" data-target="#triggerAPI"></i>
        </div>

        <div class="btn-group pull-right clear-alerts">
            <button type="button" (click)="clearAlertsForSensor()" class="btn btn-default waves-effect">Mark alerts as
                read</button>
        </div>
        <h4 class="m-t-0 header-title">
            <a class="cursor-pointer" (click)="goToHome()">Groups </a>
            <i class="ion-chevron-right i-chevron-right"></i>
            <a class="cursor-pointer" (click)="goToGroup(currentProject)">{{currentProject}} </a>
            <i class="ion-chevron-right i-chevron-right"></i>
            {{currentSensorNickname}} ({{currentSensor}})
        </h4>
    </div>
</div>

<!-- Change nickname dialog -->
<div id="editNickname" class="modal fade in">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clearPopup()">×</button>
                <h4 class="modal-title" id="myModalLabel">Change sensor nickname</h4>
            </div>
            <div class="modal-body">

                <form (ngSubmit)="setSensorNickname()" #setSensorNicknameForm="ngForm">

                    <div class="form-group">
                        <label for="name">Sensor nickname</label>
                        <input type="text" class="form-control" id="nickname" name="nickname" required [(ngModel)]="nickname"
                            placeholder="{{currentSensorNickname}}">
                    </div>

                    <div class="alert alert-success" *ngIf="saveSuccess">
                        <strong>Nickname was set to {{currentSensorNickname}}!</strong>
                    </div>

                    <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-success waves-effect waves-light" [disabled]="!setSensorNicknameForm.form.valid">Set
                        Nickname</button>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- API dialog -->
<div id="triggerAPI" class="modal fade in">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clearPopup()">×</button>
                <h4 class="modal-title" id="myModalLabel">APIs for the sensor</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="dataLoaded">
                    <div *ngFor="let api of apis" class="row row-margin-bottom">
                        <div class="alert alert-success" *ngIf="api.routing == triggerStatus && successfulTrigger">
                            <strong>{{api.textOnComplete}}</strong>
                        </div>
                        <div class="alert alert-danger" *ngIf="api.routing == triggerStatus && !successfulTrigger">
                            <strong>There was an errror with the API</strong>
                        </div>
                        <div class="col-lg-6">
                            {{ api.name | capitalize }}
                        </div>
                        <div class="col-lg-6">
                            <div class="btn-group pull-right">
                                <button type="button" class="btn btn-default waves-effect right" (click)="triggerAPI(api.routing)">
                                    {{ api.type | capitalize }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Datepicker -->
<div class="row">
    <div class="col-lg-4">
        <div class="card-box">
            <div class="btn-group pull-right">
                <input type="date" [(ngModel)]="startDateString" (ngModelChange)="updateDate()" class="date-input"
                    max="{{endDateString}}">
                <input type="time" [(ngModel)]="startDateTime" (ngModelChange)="updateDate()">
            </div>
            <h4 class="m-t-0 header-title">
                <b>Start date</b>
            </h4>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card-box">
            <div class="btn-group pull-right">
                <!-- <i class="ion-calendar text-primary"></i> -->
                <input type="date" [(ngModel)]="endDateString" (ngModelChange)="updateDate()" min="{{startDateString}}" class="date-input">
                <input type="time" [(ngModel)]="endDateTime" (ngModelChange)="updateDate()">
            </div>
            <h4 class="m-t-0 header-title">
                <b>End date</b>
            </h4>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card-box">
            <div class="btn-group pull-right">{{lastUpdated}}</div>
            <h4 class="m-t-0 header-title">Last updated</h4>
        </div>
    </div>
</div>

<!-- Loading popup -->
<div id="loading" class="modal">
    <div class="loader pull-center">
    </div>
</div>



<!-- Latest data icons -->

<div class="row">
    <div *ngIf="dataLoaded">
        <div *ngFor="let value of valuesForGroup">
            <!-- 4, 3, 2 or 1 per row for respective xl, large, medium and small screens -->
            <div *ngIf="entries > 0 && value != 'battery'" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div class="widget-panel widget-style-2 bg-white alert-border" [ngStyle]="{'border-bottom-color': widgetStyle[value].alertBorderColor}">
                    <div>
                        <div class="row nopadding">
                            <div class="col-xs-9">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h4 class="m-t-0 header-title">
                                            {{ value | capitalize }}
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-center">
                                        <h4 class="m-0 text-dark counter font-600">{{ latestValues[value] | round }}</h4>
                                        <div class="text-muted m-t-5">Latest</div>
                                    </div>
                                    <div class="col-sm-3 text-center">
                                        <h4 class="m-0 text-dark counter font-600">{{ minValues[value] | round }}</h4>
                                        <div class="text-muted m-t-5">Min</div>
                                    </div>
                                    <div class="col-sm-3 text-center">
                                        <h4 class="m-0 text-dark counter font-600">{{ avgValues[value] | round }}</h4>
                                        <div class="text-muted m-t-5">Average</div>
                                    </div>
                                    <div class="col-sm-3 text-center">
                                        <h4 class="m-0 text-dark counter font-600">{{ maxValues[value] | round }}</h4>
                                        <div class="text-muted m-t-5">Max</div>
                                    </div>
                                </div>
                            </div>
                            <div class="nopadding col-xs-3 ">
                                <i class="fa-stack-2x {{widgetStyle[value].iconType}} nopadding" [ngStyle]="{'color': widgetStyle[value].color}"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="dataLoaded && widgetStyle['battery']">
        <div id="hideBattery" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="widget-panel widget-style-2 bg-white alert-border" [ngStyle]="{'border-bottom-color': widgetStyle['battery'].alertBorderColor}">
                <div class="row nopadding">
                    <div class="col-xs-3">
                        <h4 class="m-t-0 header-title">
                            BATTERY
                        </h4>
                    </div>
                    <div class="col-xs-6">
                        <div id="latest-battery" class="circliful-chart latest-battery-sensor"
                            data-dimension="155" [attr.data-text]="latestBattery + '%'" data-info="" data-width="30"
                            data-fontsize="24" [attr.data-percent]="latestBattery" [attr.data-fgcolor]="widgetStyle['battery'].color"
                            data-bgcolor="#ebeff2" data-type="half" data-fill="#f4f8fb" data-info="Latest">
                        </div>
                    </div>

                    <div class="col-xs-3 nopadding">
                        <i class="fa-stack-2x {{widgetStyle['battery'].iconType}} nopadding" [ngStyle]="{'color': widgetStyle['battery'].color}"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="dataLoaded && widgetStyle['connection']">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="widget-panel widget-style-2 bg-white alert-border" [ngStyle]="{'border-bottom-color': widgetStyle['connection'].alertBorderColor}">
                <div class="row nopadding">
                    <div class="col-xs-9">
                        <div class="row">
                            <div class="col-xs-12">
                                <h4 class="m-t-0 header-title">
                                    CONNECTION TYPE
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 text-center">
                                <h4 class="m-0 text-dark counter font-600">{{latestNetwork}}</h4>
                                <div class="text-muted m-t-5">Network</div>
                            </div>
                            <div class="col-sm-6 text-center">
                                <h4 class="m-0 text-dark counter font-600">{{latestConnection}}</h4>
                                <div class="text-muted m-t-5">Category</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-3 nopadding">
                        <i class="fa-stack-2x {{widgetStyle['connection'].iconType}} nopadding" [ngStyle]="{'color': widgetStyle['connection'].color}"></i>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- SensorDiagram -->
<div *ngIf="this.entries < 1">
    <div class="row">
        <div class="col-lg-12">
            <div class="card-box">
                <div class="btn-group pull-right">
                    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="getLatestSensorData(); getLatestMapData()">Get
                        latest data
                        <i class="ion ion-refresh"></i>
                    </button>
                </div>
                <h2>{{ currentSensor }} contains no data in this interval</h2>
            </div>
        </div>
    </div>
</div>

<div id="graphSmallLeft" class="row">
    <div class="col-lg-6">
        <div class="card-box">
            <div class="btn-group pull-right">
                <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                    aria-expanded="false">{{ chartLeftMeasurement | capitalize }}
                    <span class="m-l-5">
                        <i class="fa ion-chevron-down"></i>
                    </span>
                </button>
                <ul class="dropdown-menu drop-menu-right" role="menu">
                    <li *ngFor="let theValue of valuesForGroup" (click)="callChangeValueLeft(theValue)">
                        <a id="{{ theValue }}">{{ theValue | capitalize }}</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a (click)="getLatestSensorData(); getLatestMapData()">Refresh</a>
                    </li>
                </ul>
            </div>
            <h4 class="m-t-0 header-title">
                <b>Sensor overview</b>
                <b *ngIf="valuesAreAverages" class="text-muted">- showing averages</b>
            </h4>

            <div id="graph-left" class="ct-chart graph-left-chart"></div>
        </div>
    </div>

    <div id="graphSmallRight" class="col-lg-6" [hidden]="mapDataLoaded">
        <div class="card-box">
            <div class="btn-group pull-right">
                <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                    aria-expanded="false">{{ chartRightMeasurement | capitalize }}
                    <span class="m-l-5">
                        <i class="fa ion-chevron-down"></i>
                    </span>
                </button>
                <ul class="dropdown-menu drop-menu-right" role="menu">
                    <li *ngFor="let theValue of valuesForGroup" (click)="callChangeValueRight(theValue)">
                        <a id="{{ theValue }}">{{ theValue | capitalize }}</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a (click)="getLatestSensorData(); getLatestMapData()">Refresh</a>
                    </li>
                </ul>
            </div>
            <h4 class="m-t-0 header-title">
                <b>Sensor overview</b>
                <b *ngIf="valuesAreAverages" class="text-muted">- showing averages</b>
            </h4>

            <div id="graph-right" class="ct-chart graph-right-chart"></div>
        </div>
    </div>


    <!-- Map -->
    <div class="col-lg-6" [hidden]="!mapDataLoaded">
        <div class="card-box">
            <div class="pull-right">

                <label class="switch" title="Show path">
                    <input type="checkbox" [(ngModel)]="showLinesToggle" (ngModelChange)="toggleLines()">
                    <span class="slider round"></span>
                </label>

            </div>
            <h4 class="m-t-0 header-title">
                <b>Sensor locations</b>
            </h4>

            <div id="googleMap" class="googleMap-sensor" (mouseenter)="pauseInterval()" (mouseleave)="continueInterval()"></div>
        </div>
    </div>
</div>


<div id="graphBig" class="row">
    <div class="col-lg-12">
        <div class="card-box">
            <div class="btn-group pull-right">
                <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                    aria-expanded="false">{{ chartBigMeasurement | capitalize }}
                    <span class="m-l-5">
                        <i class="fa ion-chevron-down"></i>
                    </span>
                </button>
                <ul class="dropdown-menu drop-menu-right" role="menu">
                    <li *ngFor="let theValue of valuesForGroup" (click)="callChangeValueBig(theValue)">
                        <a id="{{ theValue }}">{{ theValue | capitalize }}</a>
                    </li>
                    <li class="divider"></li>
                    <li>
                        <a (click)="getLatestSensorData(); getLatestMapData()">Refresh</a>
                    </li>
                </ul>
            </div>
            <h4 class="m-t-0 header-title">
                <b>Sensor overview</b>
                <b *ngIf="valuesAreAverages" class="text-muted">- showing averages</b>
            </h4>

            <div id="graph-big" class="ct-chart graph-big-chart"></div>
        </div>
    </div>
</div>
<!-- EndSensorDiagram -->