/*
    In this file we have names for reserved datatypes, and also icons. 

    IMPORTANT!
    Updating this file requires an update in /flex-cloud/global.js as well.
    Sorry for the inconvinience...
*/

export const dataTypes: any = {
    include: [          // All LoRa and Sigfox sensors send these,
        'snr',
        'rssi'
    ],
    exclude: [          // Reserved datatypes.   
        'indoor-pos',
        'gnss',
        'battery',
        'lat',
        'long',
        'gnss_wacc',
        'connection',
        'network'
    ],
    indoorPos: 'indoor-pos'
};

/**
 * List of all the icons that can be associated with a datatype. More icons can be found in assets/css/icons.css or http://coderthemes.com/ubold/boxed_dark/icons-ionicons.html
 */
export const selectableIcons: string[] = [ 
    "ion-ios7-lightbulb-outline",
    "md-directions-walk",
    "ion-waterdrop",
    "ion-thermometer",
    "ion-android-wifi",
    "md-settings-remote",
    "ion-android-locate",
    "ion-ios7-bolt",
    "ion-ios7-pulse-strong",
    "md-battery-alert",
    "ion-bluetooth",
    "ion-speedometer",
    "ion-ios7-cloud",
    "ion-ios7-location",
    "ion-connection-bars"
];
