<div class="row">
  <div class="col-lg-12">
    <h4 class="m-t-0 header-title">
      <a class="cursor-pointer" (click)="goToHome()">Groups </a>
      <i class="ion-chevron-right ion-chevron-right-small"></i>
      {{currentProject}}
    </h4>
  </div>
</div>

<div class="row">
  <div class="col-lg-4 col-sm-6">
    <div class="widget-panel widget-style-2 bg-white">
      <i class="md md-settings-remote text-primary"></i>
      <h2 *ngIf="groupNicknames[currentProject]" class="m-0 text-dark counter font-600" id="sensor-group">{{
        groupNicknames[currentProject] }}</h2>
      <h2 *ngIf="!groupNicknames[currentProject]" class="m-0 text-dark counter font-600" id="sensor-group">{{
        currentProject | capitalize }}</h2>
      <div class="text-muted m-t-5">Sensor group</div>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6">
    <div class="widget-panel widget-style-2 bg-white">
      <i class="md md-backup text-pink"></i>
      <h2 class="m-0 text-dark counter font-600" id="sensor-amount"> {{ sensorDataArray.length }}</h2>
      <div class="text-muted m-t-5">Sensor amount</div>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6">
    <div class="widget-panel widget-style-2 bg-white">
      <i class="md md-battery-alert text-info"></i>
      <div id="lowest-battery" class="circliful-chart" data-dimension="155"
        [attr.data-text]="lowestBattery + '%'" data-info="Battery" data-width="30" data-fontsize="24"
        [attr.data-percent]="lowestBattery" data-fgcolor="#34d3eb" data-bgcolor="#ebeff2" data-type="half" data-fill="#f4f8fb"></div>
    </div>
  </div>
</div>

<!-- Map -->
<div class="row">
  <div class="col-lg-12" [hidden]="!showMap">
    <div class="card-box">
      <div id="googleMap" class="googleMap-dashboard" (mouseenter)="pauseInterval()" (mouseleave)="continueInterval()"></div>
    </div>
  </div>
</div>

<div class="row">
  <!--Sensorlist-->
  <div class="col-lg-6">
    <div class="card-box">
      <a (click)="goToHome()" class="cursor-pointer pull-right btn btn-default btn-sm waves-effect waves-light">View
        All</a>
      <h4 class="text-dark header-title m-t-0">Sensor list</h4>
      <p class="text-muted m-b-30 font-13">All sensors in project are listed below.</p>

      <div class="table-responsive">
        <table class="table table-actions-bar">
          <thead>
            <tr>
              <th (click)="sortListBy('Sensor')" class="cursor-pointer">Sensor</th>
              <th (click)="sortListBy('Updated')" class="cursor-pointer">Updated</th>
              <th (click)="sortListBy('Battery')" class="cursor-pointer">Battery</th>
              <th>Status</th>

              <!-- <th>Type</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody id="sensor-list">
            <tr *ngFor="let sensor of sensorDataArray" (click)="goToSensor(currentProject, sensor.sensorName)" class="list-link">


              <td> {{sensor.sensorNickname}} </td>
              <td> {{sensor.lastModified | date:'yyyy-MM-dd HH:mm' }} </td>
              <td> {{sensor.sensorData.battery | round }}%</td>
              <td title="{{sensor.triggeredAlerts}}" >
                <i *ngIf="statusFlagForSensor[sensor.sensorName]" class="ion-alert alert-icon"></i>
              </td>

            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
  <!-- end col -->

  <!-- SensorDiagram -->
  <div class="col-lg-6">
    <div class="card-box">
      <div class="btn-group pull-right">
        <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
          aria-expanded="false">{{ measurement | capitalize }}
          <span class="m-l-5">
            <i class="fa ion-chevron-down"></i>
          </span>
        </button>
        <ul class="dropdown-menu drop-menu-right" role="menu">
          <li *ngFor="let theValue of valuesForGroup" (click)="callChangeValue(theValue)">
            <a id="{{ theValue }}">{{ theValue | capitalize }}</a>
          </li>
          <li class="divider"></li>
          <li>
            <a (click)="getLatestDataAllSensors(); getLatestMapData()">Refresh</a>
          </li>
        </ul>
      </div>
      <h4 class="m-t-0 header-title">
        <b>Project overview</b>
      </h4>
      <p id="chart-title" class="text-muted m-b-30 font-13"> {{ measurement | capitalize }} </p>

      <div id="distributed-series" class="ct-chart bar-chart"></div>
    </div>
  </div>
</div>

<!-- Alerts -->
<div class="row">
  <div class="col-lg-12">
    <div class="card-box">
      <h4 class="text-dark header-title m-t-0">Alerts</h4>

      <div class="table-responsive">
        <table class="table table-actions-bar">
          <thead>
            <tr>
              <th (click)="sortListBy('')" class="cursor-pointer">Alert name</th>
              <th (click)="sortListBy('')" class="cursor-pointer">Applies to sensors</th>
              <th (click)="sortListBy('')" class="cursor-pointer">Datatype</th>
              <th (click)="sortListBy('')" class="cursor-pointer">Evaluation</th>
              <th (click)="sortListBy('')" class="cursor-pointer">Trigger value</th>
              <!-- <th>Type</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody id="alert-list">
            <tr *ngFor="let alert of alertArray" class="list-link">
              <td> {{alert.alertName}} </td>
              <td> {{convertToDisplayName(alert.appliesTo)}} </td>
              <td> {{alert.dataType}}</td>
              <td> {{alert.evaluation | formatevaluation}}</td>
              <td> {{alert.triggerValue}}</td>
              <td (click)="deleteAlert(alert)" title="Delete alert">
                <i class="ion-trash-b cursor-pointer"></i>
              </td>
              <td (click)="initEditAlert(alert)" title="Edit alert" data-toggle="modal" data-target="#alertModal" > 
                  <i class="md-edit cursor-pointer"></i>
                </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-group pull-right">
          <button class="cursor-pointer btn btn-default btn-sm waves-effect waves-light" (click)="initNewAlert()"
            data-toggle="modal" data-target="#alertModal">
            Add Alert
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- End Alerts -->


<!-- Add alert modal -->
<div id="alertModal" class="modal fade in">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            <h4 class="modal-title" id="myModalLabel">Alerts settings</h4>
          </div>
        </div>
      </div>
      <div class="modal-body ">
        <form (ngSubmit)="saveAlert()" #createProjectForm="ngForm">
          <div class="row">
            <div class="form-group col-lg-12">
              <label for="name">Alert name</label>
              <input type="text" class="form-control" id="name" name="name" required [(ngModel)]="currentAlert.alertName">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12">
              <div class="form-group btn-group">
                <label for="appliesTo" class="label-block">Applies to:</label>
                <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                  aria-expanded="false">Choose sensors
                  <span class="m-l-5">
                    <i class="fa ion-chevron-down"></i>
                  </span>
                </button>
                <ul class="dropdown-menu drop-menu-right" role="menu" (click)="showDropdown($event)">
                  <li>
                    <label class="dropdown-menu-item">
                      <input type="checkbox" [checked]="newAlertIsAllChecked" (change)="newAlertIsAllChecked = !newAlertIsAllChecked"
                        (click)="toggleAllCheckBox($event)" />
                      <span>All</span>
                    </label>
                  </li>
                  <li class="divider"></li>
                  <li *ngFor="let sensor of sensorDataArray">
                    <label class="dropdown-menu-item">
                      <input type="checkbox" [checked]="newAlertAppliesTo[sensor.sensorName]" (click)="toggleCheckBox($event, sensor)" />
                      <span>{{sensor.sensorName}}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-3">
              <div class="form-group btn-group">
                <label class="label-block">Datatype</label>
                <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                  aria-expanded="false">{{ currentAlert.dataType }}
                  <span class="m-l-5">
                    <i class="fa ion-chevron-down"></i>
                  </span>
                </button>
                <ul class="dropdown-menu drop-menu-right" role="menu">
                  <li *ngFor="let theValue of valuesForGroup">
                    <label class="dropdown-menu-item" (click)="setNewAlertDatatype(theValue)">
                      {{ theValue | capitalize }}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group btn-group col-lg-3">
              <label class="label-block">Evaluation</label>
              <button type="button" class="btn btn-default dropdown-toggle waves-effect waves-light" data-toggle="dropdown"
                aria-expanded="false">{{ currentAlert.evaluation | formatevaluation }}
                <span class="m-l-5">
                  <i class="fa ion-chevron-down"></i>
                </span>
              </button>
              <ul class="dropdown-menu drop-menu-right" role="menu">
                <li *ngFor="let evaluation of evaluationTypes">
                  <label class="dropdown-menu-item" (click)="setNewAlertEvaluation(evaluation)">
                      {{ evaluation | formatevaluation }}
                  </label>
                </li>
              </ul>
            </div>
            <div>
              <div class="form-group col-lg-4">
                <label for="name">Trigger value</label>
                <input type="text" class="form-control" [(ngModel)]="currentAlert.triggerValue" id="name" name="trigger_value"
                  pattern="^-?[0-9]\d*(\.\d+)?$" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="{{alertErrorClass}}">{{newAlertErrorMessage}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-success waves-effect waves-light">Save alert</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>