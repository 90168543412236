import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
//import { Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { SensorData } from './sensor-data';
 

@Injectable()
export class SensorService {
  constructor(private http: HttpClient, private datePipe: DatePipe) { }
  private dbUrl = '/'   
  
  getLatestDataAllSensors(currentProject: string): Promise<any> {
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'group/latest?groupName=' + currentProject))
      //.toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getLatestGeoDataForGroup(currentProject: string): Promise<any> {
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'group/geodata/latest?groupName=' + currentProject))
      //.toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }
  
  getLatestSensorData(currentSensor: string, currentProject: string): Promise<any> {    
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'sensor/latest?sensorName=' + currentSensor + '&sensorType=' + currentProject))
      //.toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getLatestSensorGeoData(currentSensor: string, currentProject: string): Promise<any> {    
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'sensor/geodata/latest?sensorName=' + currentSensor + '&sensorType=' + currentProject))
      //.toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getSensorDataInterval(currentSensor: string, currentProject: string, startDate: Date, endDate: Date): Promise<any> {
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'sensor/interval?sensorName=' + currentSensor + '&sensorType=' + currentProject + '&start=' + startDate.getTime()/1000 + '&end=' + endDate.getTime()/1000))
      //.toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getSensorGeoDataInterval(currentSensor: string, currentProject: string, startDate: Date, endDate: Date): Promise<any> {
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'sensor/geodata/interval?sensorName=' + currentSensor + '&sensorType=' + currentProject + '&start=' + startDate.getTime()/1000 + '&end=' + endDate.getTime()/1000))
      //.toPromise()
      .then(this.extractData)
      .catch(this.handleError);
  }

  getSensor(currentSensor: string, currentProject: string): Promise<any>{
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'sensor?name=' + currentSensor + '&group=' + currentProject))
      //.toPromise()
      .then(this.extractData)      
      .catch(this.handleError);
  }

  
  getAlertsForSensor(currentSensor: string, currentProject: string): Promise<any> {
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'sensor/alerts?sensorName=' + currentSensor + '&groupName=' + currentProject, {}))
        //.toPromise()
        .then(this.extractData)
        .catch(this.handleError);
  }

  clearSensorAlerts(currentSensor: string, currentProject: string): Promise<any> {
    return lastValueFrom(this.http.put<HttpResponse<any>>(this.dbUrl + 'sensor/clearalerts?sensorName=' + currentSensor + '&groupName=' + currentProject, {}))
        //.toPromise()
        .catch(this.handleError);
  }

  setSensorNickname(currentSensor: string, currentProject: string, nickname: string): Promise<any> {
    //let headers = new Headers({ 'Content-Type': 'application/json' });
    //let options = new RequestOptions({ headers: headers });       
    return lastValueFrom(this.http.put<HttpResponse<any>>(this.dbUrl + 'sensor/nickname?name=' + currentSensor + '&nickname=' + nickname + '&group=' + currentProject, {}))
        //.toPromise()
        .catch(this.handleError);
  }

  private extractData(res: HttpResponse<any>) {    
    return res;
  }

  private handleError(error: HttpResponse<any> | any){
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof HttpResponse) {
        //const body = error.json() || '';
        const err = error.body.error || JSON.stringify(error);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
        errMsg = error.message ? error.message : error.toString();
    }
    console.error("Error with http request:");
    console.error(errMsg);
    return {};     
  }
}