import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
//import { Headers, Response, RequestOptions } from '@angular/http';
import { HttpHeaders } from '@angular/common/http'
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
 

@Injectable()
export class AlertService {
  constructor(private http: HttpClient, private datePipe: DatePipe) { }
  private dbUrl = '/'   
  
  getGroupAlerts(currentProject: string): Promise<any> {
    //console.log("currentproject", currentProject);
    //console.log("Url", this.dbUrl + 'alerts?groupName=' + currentProject);
    
    return lastValueFrom(this.http.get<HttpResponse<any>>(this.dbUrl + 'alerts?groupName=' + currentProject))
      .then(this.extractData)
      .catch(this.handleError);
  }

  createAlert(newAlert): Observable<HttpResponse<any>> {
    //console.log("Trying to post object", newAlert);
    return this.http.post<HttpResponse<any>>(this.dbUrl + 'alert', newAlert)
  }

  deleteAlert(alertToDelete): Observable<HttpResponse<any>> {
    //console.log("Trying to delete alert", alertToDelete);
    return this.http.delete<HttpResponse<any>>(`${this.dbUrl}alert?id=${alertToDelete._id}`);
  }

  editAlert(alertToEdit): Observable<HttpResponse<any>> {
    //console.log("Trying to edit alert", alertToEdit);
    return this.http.put<HttpResponse<any>>(this.dbUrl + 'alert', alertToEdit);
  }

  private extractData(res: HttpResponse<any>) {
    return res;
  }

  // private handleError(error: Response | any){
  //   // In a real world app, we might use a remote logging infrastructure
  //   let errMsg: string;    
  //   if (error instanceof Response) {
  //       const body = error.json() || '';
  //       const err = body.error || JSON.stringify(body);
  //       errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  //   } else {
  //       errMsg = error.message ? error.message : error.toString();
  //   }
  //   console.error("Error with http request:");
  //   console.error(errMsg);
  //   return {};     
  // }

  private handleError(error: HttpResponse<any> | any){
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;    
    if (error instanceof HttpResponse) {
        //const body = error.json() || '';
        const err = error.body.error || JSON.stringify(error);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
        errMsg = error.message ? error.message : error.toString();
    }
    console.error("Error with http request:");
    console.error(errMsg);
    return {};     
  }
}

