
import { Directive, ElementRef, Input } from '@angular/core';

declare var $: any;

@Directive({selector: '[circlifulDirective]' })
export class CirclifulDirective {
    constructor(el: ElementRef) {
        $('#latest-battery.circliful-chart').circliful();
    }
}