import { Component, OnInit, NgZone, Inject } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { ProjectService } from "./shared/project.service";
import { SensorService } from "./shared/sensor.service";
import { MessageService } from "./shared/message.service";
import "./rxjs-operators";
import { AuthenticationService } from "./auth/authService";

import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { Observable, filter, map } from 'rxjs';
//import { filter } from "rxjs/operator/filter";

// reference to jQuery
declare var $: any;

@Component({
  selector: "cloud",
  templateUrl: "./app.component.html",
  providers: [ProjectService, SensorService, ToastrService]
})
export class AppComponent implements OnInit {
  isLoggedin: boolean;
  groups = [];
  sensors = [];
  selectedGroup: string;
  isCollapsed: boolean = false;
  user = { email: "" };
//   title = 'okta-angular-quickstart';
  public isAuthenticated$!: Observable<boolean>;

  constructor(
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private sensorService: SensorService,
    private router: Router,
    private zone: NgZone,
    private messageService: MessageService,
    private toastr: ToastrService,
    private _oktaStateService: OktaAuthStateService, 
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) {}

  getGroups(): void {
    this.projectService.getGroups().then(groups => {
      if (Object.keys(groups).length === 0 && groups.constructor === Object) {
        groups = [];
      }

      this.groups = groups;
    });
  }

  logoutUser() {
    this.authenticationService.logout();
    this.router.navigateByUrl("/");
  }

  // public async logoutUser(): Promise<void> {
  //   await this._oktaAuth.signOut();
  //   this.router.navigateByUrl("/");
  // }

  getSensors(): void {
    this.sensorService
      .getLatestDataAllSensors(this.selectedGroup)
      .then(sensors => {
        this.sensors = [];
        for (let sensor in sensors) {
          this.sensors.push(sensor);
        }
        this.sensors.sort();
      });
  }

  updateComponent() {
    this.getGroups();
    this.sensors = [];
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url.toString() === "/" && this.isLoggedin) {
          this.sensors = [];
          this.router.navigateByUrl("/start");
        }
        if (val.url.toString().indexOf("/dashboard") !== -1) {
          let temp = val.url
            .toString()
            .slice(val.url.toString().indexOf("/") + 1);
          temp = temp.slice(temp.indexOf("/") + 1);
          if (temp.indexOf("/") !== -1) {
            temp = temp.slice(0, temp.lastIndexOf("/"));
          }
          this.selectedGroup = temp;
          this.getSensors();
        }
      }
    });
  }

  goToHome() {
    this.router.navigateByUrl("/");
  }

  ngOnInit(): void {
    // this.authenticationService.isUserLoggedIn.subscribe(value => {
    //   this.isLoggedin = value;
    //   if (this.isLoggedin) {
    //     this.updateComponent();
    //   }
    // });

    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    // this.isAuthenticated$.subscribe(value => console.log('log:', value));//TODO: Could be replaced with (isAuthenticated$ | async)
    this.isAuthenticated$.subscribe(value => {
      this.isLoggedin = value;
      if (this.isLoggedin) {
        this.authenticationService.login();
        this.updateComponent();
      }
    });

    this.authenticationService.user.subscribe(user => {
      this.user = user;
    });

    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.timeOut = 1000;

    this.messageService.error.subscribe(error => {
      if (error) {
        //console.log("Got error: ", error);
        this.toastr.error(error, "Error", {
          tapToDismiss: true,
          timeOut: 5000
        });
      }
    });

    this.messageService.message.subscribe(msg => {
      if (msg) {
        //console.log("Got message: ", msg);
        this.toastr.success(msg, "Success", {
          tapToDismiss: true
        });
      }
    });

    this.messageService.warning.subscribe(warn => {
      if (warn) {
        //console.log("Got warning: ", warn);
        this.toastr.warning(warn, "", {
          tapToDismiss: true,
        });
      }
    });
  }
}
