import {Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'round'})
export class RoundPipe implements PipeTransform {

    transform(value:any) {
        if (value) {
            return Math.round(value * 10) / 10;
        }
        return value;
    }
    transform3Decimal(value:any) {
        if (value) {
            return Math.round(value * 1000) / 1000;
        }
        return value;
    }

}
