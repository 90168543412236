import { Component, OnInit, SecurityContext } from "@angular/core";
import { Router } from "@angular/router";

import { ProjectService } from "../shared/project.service";
import { CapitalizePipe } from "../shared/capitalize.pipe";
import { DataFormats } from "./../shared/dataformats";
import { RoundPipe } from "./../shared/round.pipe";
import { selectableIcons } from "./../shared/global";
import { MessageService } from "../shared/message.service";
import { AuthenticationService } from "../auth/authService";
import { DomSanitizer } from '@angular/platform-browser';
//import { error } from "util";

declare var $: any;

@Component({
  templateUrl: "./start.component.html",
  providers: [ProjectService]
})
export class StartComponent {
  xValue: string = "1";
  index: number = 0;
  groups: any[];
  selectedGroup: any;
  dataLoaded = false;
  currentDataFormats: any[];
  currentValueType: string = "signed";
  currentDataFormat: DataFormats;
  newGroupName: string;
  newGroupNickname: string;
  selectableIcons: string[];
  conversionEquals: string;
  conversionFail: boolean = false;
  usingHeaders: boolean;
  indexOfDataFormat: number = 1;
  isGroupUpdating: any = {};
  refreshFailure: boolean = false;
  locationServiceProviders: string[] = ["Google", "HERE"];
  currentServiceLocationProvider: string = this.locationServiceProviders[0];
  usersForSelectedGroup: any[];
  usersForGroups = {};
  newUserEmail: string;
  currentUser = {
    email: "",
    role: "",
    hasAccessForSelectedGroup: false,
    sub:""
  };

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private roundPipe: RoundPipe,
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) {}

  getGroups(): void {
    this.projectService.getGroups().then(groups => {
      if (Object.keys(groups).length === 0 && groups.constructor === Object) {
        groups = [];
      }
      this.groups = groups;
      this.selectedGroup = this.groups[0];
      this.dataLoaded = true;

      if (this.selectedGroup) {
        this.usingHeaders = this.selectedGroup.usingHeaders;
        this.currentServiceLocationProvider = this.selectedGroup.locationServiceProvider;
        this.groups.forEach(item => {
          this.projectService.getUsersForGroup(item.groupName).then(users => {
            this.usersForGroups[item.groupName] = users;
          });
          this.isGroupUpdating[item.groupName] = false;
        });
      }
    });
  }
  updateDataFormatsOrder(): void {
    let fromIndex = this.currentDataFormats.indexOf(this.currentDataFormat);
    this.currentDataFormats.splice(fromIndex, 1);
    this.currentDataFormats.splice(
      this.indexOfDataFormat - 1,
      0,
      this.currentDataFormat
    );
  }

  getGroupDataFormats(group): void {
    this.setSelectedGroup(group);
    //console.log("Selected group", group);

    this.newUserEmail = "";
    this.usersForSelectedGroup = this.usersForGroups[group.groupName];
    this.currentDataFormats = [];
    this.usingHeaders =
      this.selectedGroup.usingHeaders == undefined
        ? true
        : this.selectedGroup.usingHeaders;
    this.currentServiceLocationProvider =
      this.selectedGroup.locationServiceProvider === undefined
        ? "Google"
        : this.selectedGroup.locationServiceProvider;

    this.projectService
      .getGroupDataFormats(group.groupName)
      .then(dataformats => {
        $(function() {
          $('[data-toggle="popover"]').popover();
        });
        for (let dataformat of dataformats) {
          let temp = new DataFormats(
            dataformat._id,
            dataformat.dataType,
            dataformat.header,
            dataformat.length,
            dataformat.preValueConv,
            dataformat.postValueConv,
            dataformat.icon,
            dataformat.unit,
            dataformat.isSigned
          );
          this.currentDataFormats.push(temp);
        }
        if (this.currentDataFormats.length <= this.index) {
          this.index = 0;
        }
        if (this.currentDataFormats.length > 0) {
          this.currentDataFormat = this.currentDataFormats[this.index];
          this.currentValueType = this.currentDataFormats[this.index].isSigned
            ? "signed"
            : "unsigned";
          this.indexOfDataFormat = this.index + 1;
        } else {
          this.callAddDataFormat();
        }

        this.calculateConversion();
        //console.log("Got " + this.currentDataFormats.length + " dataformats");
      });
  }

  getUsersForGroup(group) {
    this.projectService.getUsersForGroup(group).then(users => {
      this.usersForGroups[group] = users;
      //console.log("Got members", users);

      if (this.selectedGroup.groupName === group) {
        //console.log("Updated users for selected");
        this.usersForSelectedGroup = users;
      }
    });
  }

  callChangeLocationServiceProvider(provider) {
    const old = this.currentServiceLocationProvider;
    this.currentServiceLocationProvider = provider;
    this.selectedGroup.locationServiceProvider = this.currentServiceLocationProvider;
    let subscription = this.projectService
      .updateGroup(this.selectedGroup)
      .subscribe(
        res => {
          subscription.unsubscribe();
          this.messageService.setMessage("Updated project");
        },
        error => {
          this.currentServiceLocationProvider = old;
          this.selectedGroup.locationServiceProvider = old;
          //console.log("Got error: ", error);
        }
      );
  }

  removeUserFromProject(user) {
    this.projectService
      .removeUserFromGroup(this.selectedGroup.groupName, user.email)
      .subscribe(res => {
        this.getUsersForGroup(this.selectedGroup.groupName);
        if (user.email === this.currentUser.email){
          this.getGroups();
          $("#editProject").modal("hide");
        }
      });
  }

  updateWriteAccess(groupName, user) {
    // Clickevent triggers before variable updates, therefore inversion.
    this.projectService
      .setWriteAccess(groupName, user.email, !user.writeAccess)
      .subscribe(
        res => {
          this.setCurrentUserAccessForSelectedGroup();
          this.messageService.setMessage('Updated permissions');
        },
        err => {
          this.getUsersForGroup(this.selectedGroup.groupName);
        }
      );
  }

  updateEmailAlerts(groupName, user) {
    // Clickevent triggers before variable updates, therefore inversion.
    this.projectService
      .setEmailAlerts(groupName, user.email, !user.emailAlerts)
      .subscribe(
        res => {
          this.setCurrentUserAccessForSelectedGroup();
          this.messageService.setMessage('Updated alert settings');
        },
        err => {
          this.getUsersForGroup(this.selectedGroup.groupName);
        }
      );
  }

  addUserToProject() {
    //console.log("userforSelectedGroup", this.usersForSelectedGroup);
    this.projectService
      .addUserToGroup(this.selectedGroup.groupName, this.newUserEmail)
      .subscribe(
        res => {
          this.newUserEmail = "";
          this.getUsersForGroup(this.selectedGroup.groupName);
          this.messageService.setMessage('Added member to project');
        },
        err => {
          //console.log("Got error", err);
        }
      );
  }

  callChangeDataFormat(dataformat) {
    this.currentDataFormat = dataformat;
    this.calculateConversion();
    this.indexOfDataFormat =
      this.currentDataFormats.indexOf(this.currentDataFormat) + 1;
    this.currentValueType = this.currentDataFormat.isSigned
      ? "signed"
      : "unsigned";
  }

  callAddDataFormat() {
    if (!this.currentUser.hasAccessForSelectedGroup) return;
    this.currentDataFormat = new DataFormats(
      "",
      "New dataformat",
      "",
      0,
      "x",
      ""
    );
    this.calculateConversion();
    this.currentDataFormats.push(this.currentDataFormat);
    this.indexOfDataFormat =
      this.currentDataFormats.indexOf(this.currentDataFormat) + 1;
  }

  saveDataformat() {
    if (this.usingHeaders) {
      if (this.currentDataFormat._id === "") {
        //console.log("Trying to save new data format");
        let subscription = this.projectService
          .addGroupDataFormat(
            this.selectedGroup["groupName"],
            this.currentDataFormat
          )
          .subscribe(
            res => {
              this.index = this.currentDataFormats.length - 1;
              this.getGroupDataFormats(this.selectedGroup);
              this.messageService.setMessage("Dataformat was saved!");
              subscription.unsubscribe();
            },
            error => {
              //console.log("Got error: ", error);
            }
          );
      } else {
        //console.log("Trying to update data format");
        let subscription = this.projectService
          .updateGroupDataFormat(this.currentDataFormat)
          .subscribe(
            res => {
              this.index = this.currentDataFormats.indexOf(
                this.currentDataFormat
              );
              this.getGroupDataFormats(this.selectedGroup);
              this.messageService.setMessage("Dataformat was saved!");
              subscription.unsubscribe();
            },
            error => {
              //console.log("Got error: ", error);
            }
          );
      }
    } else {
      // Delete all dataformats and then recreate them to get them in the right order since we are not using headers to identify data
      let dataformatsToDelete = this.currentDataFormats.filter(function(
        dataformat
      ) {
        return dataformat["_id"] !== ""; // Do not delete unsaved dataformats
      });
      //console.log("Trying to save data format in the right order");
      if (dataformatsToDelete.length > 0) {
        for (let dataformat in dataformatsToDelete) {
          let subscription = this.projectService
            .deleteGroupDataFormat(dataformatsToDelete[dataformat])
            .subscribe(
              res => {
                dataformatsToDelete[dataformat]._id = "";
                if (Number(dataformat) == dataformatsToDelete.length - 1) {
                  for (let dataformatToSave of this.currentDataFormats) {
                    this.projectService
                      .addGroupDataFormat(
                        this.selectedGroup["groupName"],
                        dataformatToSave
                      )
                      .subscribe(res => {
                        if (
                          res.status == 201 &&
                          this.currentDataFormats.length - 1 ==
                            this.currentDataFormats.indexOf(dataformatToSave)
                        ) {
                          setTimeout(
                            function() {
                              this.index = this.currentDataFormats.indexOf(
                                this.currentDataFormat
                              );
                              this.getGroupDataFormats(this.selectedGroup);
                            }.bind(this),
                            100
                          );
                          this.messageService.setMessage(
                            "Dataformat was saved!"
                          );
                        }
                      });
                  }
                }
                subscription.unsubscribe();
              },
              error => {
                //console.log("Got error: ", error);
              }
            );
        }
      } else {
        for (let dataformatToSave of this.currentDataFormats) {
          let subscription = this.projectService
            .addGroupDataFormat(
              this.selectedGroup["groupName"],
              dataformatToSave
            )
            .subscribe(
              res => {
                if (
                  res.status == 201 &&
                  this.currentDataFormats.length - 1 ==
                    this.currentDataFormats.indexOf(dataformatToSave)
                ) {
                  this.index = this.currentDataFormats.indexOf(
                    this.currentDataFormat
                  );
                  this.getGroupDataFormats(this.selectedGroup);
                  this.messageService.setMessage("Dataformat was saved!");
                }
                subscription.unsubscribe();
              },
              error => {
                //console.log("Got error: ", error);
              }
            );
        }
      }
    }
  }

  setIsUsingHeaders() {
    const old = this.selectedGroup.usingHeaders;
    let updatedGroup = this.selectedGroup;
    // Invert bool in request as (click) function is called before the databinding 
    updatedGroup.usingHeaders = !this.selectedGroup.usingHeaders;
    let subscription = this.projectService
      .updateGroup(updatedGroup)
      .subscribe(
        res => {
          subscription.unsubscribe();
          this.messageService.setMessage("Project updated");
        },
        error => {
          this.selectedGroup.usingHeaders = old;
          //console.log("Got error: ", error);
        }
      );
  }

  saveProjectSettings() {
    //console.log("Users", this.usersForSelectedGroup);

    if (
      this.usingHeaders != this.selectedGroup.usingHeaders ||
      this.currentServiceLocationProvider !=
        this.selectedGroup.locationServiceProvider
    ) {
      this.selectedGroup.usingHeaders = this.usingHeaders;
      this.selectedGroup.locationServiceProvider = this.currentServiceLocationProvider;
      let subscription = this.projectService
        .updateGroup(this.selectedGroup)
        .subscribe(
          res => {
            subscription.unsubscribe();
            this.messageService.setMessage("Project was saved!");
          },
          error => {
            //console.log("Got error: ", error);
          }
        );
    } else {
      this.messageService.setWarning("Nothing to save");
    }
  }

  onValueTypeChange() {
    if (this.currentValueType === "signed") {
      this.currentDataFormat.isSigned = true;
    } else {
      this.currentDataFormat.isSigned = false;
    }
  }

  deleteCurrentDataformat() {
    //console.log(this.currentDataFormat._id);
    let subscription = this.projectService
      .deleteGroupDataFormat(this.currentDataFormat)
      .subscribe(
        () => {
          this.index =
            this.currentDataFormats.indexOf(this.currentDataFormat) - 1;
          this.getGroupDataFormats(this.selectedGroup);
          this.messageService.setMessage("Dataformat deleted.");
          subscription.unsubscribe();
        },
        error => {
          //console.log("Got error: ", error);
        }
      );
  }

  refreshGroupData() {
    this.isGroupUpdating[this.selectedGroup.groupName] = true;
    let subscription = this.projectService
      .refreshGroupData(this.selectedGroup)
      .subscribe(
        res => {          
          this.isGroupUpdating[this.selectedGroup.groupName] = false;
          subscription.unsubscribe();
          this.messageService.setMessage("Sucessfully refreshed project data")
        },
        error => {
          this.isGroupUpdating[this.selectedGroup.groupName] = false;
        }
      );
  }

  createNewProject() {
    //console.log("Trying to create new project");
    let subscription = this.projectService
      .createNewGroup(this.newGroupName, this.newGroupNickname)
      .subscribe(
        res => {
          this.newGroupName = "";
          this.newGroupNickname = "";
          this.getGroups();
          $("#name").val("");
          $("#nickname").val("");
          $("#addProject").modal("hide");
          subscription.unsubscribe();
        },
        error => {
          //console.log("Got error: ", error);
        }
      );
  }

  deleteProject() {
    //console.log(
    //   "Trying to delete " +
    //     this.selectedGroup["groupName"] +
    //     ", id:" +
    //     this.selectedGroup["_id"]
    // );
    let subscription = this.projectService
      .deleteGroup(this.selectedGroup["_id"])
      .subscribe(
        res => {
          this.getGroups();
          subscription.unsubscribe();
        },
        error => {
          //console.log("Got error: ", error);
        }
      );
  }

  setGroupNickname(nickname): void {
    let subscription = this.projectService
      .setGroupNickname(this.selectedGroup["groupName"], nickname)
      .subscribe(
        res => {
          this.getGroups();
          $("#nicknameInput").val("");
          $("#editNickname").modal("hide");
          subscription.unsubscribe();
        },
        error => {
          //console.log("Got error: ", error);
        }
      );
  }

  hasRemoveAccess(group) {
    return (
      this.currentUser.role === "Admin" ||
      this.currentUser.email === group.groupOwner
    );
  }

  hasUserRemoveAccess(user) {
    const isAdmin = this.currentUser.role === "Admin";
    const isGroupOwner = this.currentUser.email === this.selectedGroup.groupOwner;
    const userIsGroupOwner = user.email === this.selectedGroup.groupOwner;
    const userHasWriteAccess = this.currentUser.hasAccessForSelectedGroup && !user.hasWriteAccess;
    const isSelf = this.currentUser.email === user.email;

    return isAdmin || (isGroupOwner && !isSelf) || (userHasWriteAccess && !userIsGroupOwner) || (isSelf && !isGroupOwner);
  }

  hasWriteAccess(user) {
    const isAdmin = this.currentUser.role === "Admin";
    const isGroupOwner = this.currentUser.email === this.selectedGroup.groupOwner;
    const userIsGroupOwner = user.email === this.selectedGroup.groupOwner;
    const userHasWriteAccess = this.currentUser.hasAccessForSelectedGroup && !user.hasWriteAccess;
    const isSelf = this.currentUser.email === user.email;  
    return (isAdmin) || (isGroupOwner && !isSelf) || (userHasWriteAccess && !userIsGroupOwner && !isSelf);
  }

  setCurrentUserAccessForSelectedGroup() {
    let hasAccess = false;
    this.usersForSelectedGroup.forEach(user => {
      if (user.writeAccess && this.currentUser.email === user.email) {
        hasAccess = true;
      }
    });
    if(this.currentUser.role === "Admin") hasAccess = true;

    this.currentUser.hasAccessForSelectedGroup = hasAccess;
  }

  setSelectedGroup(group): void {
    this.newUserEmail = "";
    this.selectedGroup = group;
    this.usersForSelectedGroup = this.usersForGroups[group.groupName];
    this.setCurrentUserAccessForSelectedGroup();
    this.usingHeaders = this.selectedGroup.usingHeaders;
    this.currentServiceLocationProvider = this.selectedGroup.locationServiceProvider;
  }

  goToDashboard(group): void {
    let link = ["/dashboard", group];
    this.router.navigate(link);
  }

  calculateConversion(): void {
    let x = this.xValue;     //Assigns a value to x to be used in eval
    let sanitizedPreValue = this.currentDataFormat.preValueConv;
    try {
      // Escape(strip anything other than digits, (), -+/*. and x) and sanitize     
      sanitizedPreValue = this.sanitizer.sanitize(SecurityContext.HTML, sanitizedPreValue.replace(/[^-()\d/*+.x]/g, ''));
      
      let evalX = eval(sanitizedPreValue);
      this.conversionEquals = this.roundPipe.transform(Number(evalX));
      this.conversionFail = false;
    } catch (error) {
      this.conversionFail = true;
      this.conversionEquals = "Invalid";
    }
  }

  ngOnInit(): void {
    this.getGroups();
    this.currentDataFormat = new DataFormats("", "", "", 0, "", "");
    this.currentUser = this.authenticationService.getCurrentUser();
    this.currentUser.email = this.currentUser.sub;
    this.selectableIcons = selectableIcons; // ["ion-ios7-lightbulb-outline", "md-directions-walk", "ion-waterdrop", "ion-thermometer", "ion-android-wifi", "md-settings-remote", "fa-compress"];

    $('[data-toggle="popover"]').popover({
      html: true,
      dataContainer: ".modal-body"
    });

    $('body').on('click', function (e) {
      //did not click a popover toggle or popover
      if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) { 
          $('[data-toggle="popover"]').popover('hide');
      }
  });
  }
}
