import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';

declare const google: any;
declare const MarkerClusterer: any;

@Injectable()
export class Map {
  map: any;
  locationData: any[];
  lines: any[];
  locations: any[];


  constructor(
    private datePipe: DatePipe
  ) { }

  /**
   * 
   * @param markerFunction 
   * @param markerTitleFunction 
   * @param locationData 
   */
  init(markerFunction, markerTitleFunction, locationData): boolean {
    //console.log('Init map: ');
    //console.log(locationData);
    let lat: number;
    let long: number;

    //The first data point will be in focus
    lat = locationData[0]['lat'] == undefined ? 58 : locationData[0]['lat'];
    long = locationData[0]['long'] == undefined ? 15 : locationData[0]['long'];
    let mapProp = {
      center: new google.maps.LatLng(lat, long),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(document.getElementById("googleMap"), mapProp);

    //Inserts markers for the datapoints
    let infowindow = [];
    let markers = [];
    let bounds = new google.maps.LatLngBounds();
    this.locations = [];
    for (let index in locationData) {
      let latLng = new google.maps.LatLng(locationData[index]['lat'], locationData[index]['long']);
      bounds.extend(latLng);
      this.locations.push(latLng);
      let marker = new google.maps.Marker({
        position: latLng,
        title: markerTitleFunction(locationData[index], this.datePipe)
      });
      infowindow[index] = new google.maps.InfoWindow({
        content: markerFunction(locationData[index], this.datePipe)
      });
      marker.addListener('click', function (event) {
        infowindow.forEach(value => {
          value.close();
        })
        infowindow[index].open(this.map, marker);
      });

      if (locationData[index]['radius'] != undefined && (locationData.length - Number(index)) < 5) {    // Add circle to show accuracy for the latest 5 locations
        let circle = new google.maps.Circle({
          map: this.map,
          radius: Number(locationData[index]['radius']),
          strokeOpacity: 0,
          fillOpacity: 0.1,
          fillColor: '#0099ff'
        });
        circle.bindTo('center', marker, 'position');
        bounds.union(circle.getBounds());
      }

      // If marker is placed at exact same location, the newer will override the old to avoid problems with clusters
      let indexOfSameLocation = markers.indexOf(markers.filter(oldMarker => {
        return (oldMarker.getPosition().equals(marker.getPosition()))
      })[0]);
      if (indexOfSameLocation !== -1) {
        markers[indexOfSameLocation] = marker;
      } else {
        markers.push(marker);
      }
    }
    markers.forEach(marker => {
      marker.setMap(this.map);
    });
    if (locationData.length > 5) {
      let markerCluster = new MarkerClusterer(this.map, markers,
        { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
    }
    if (locationData.length > 1) {
      this.map.fitBounds(bounds);         // If there are more than 2 location points, the map will fit all of them inside the map view with the most zoom possible
    }
    this.drawLines();
    return true;
  }

  loadMap(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (typeof google === 'undefined') {
        $(document).ready(() => {
          // Make a request to the server to get the Google Maps JavaScript API
          $.ajax({
            url: '/google-maps-api',
            type: 'GET',
            dataType: 'json',
            cache: true,
            success: (data) => {
              const { body, nonce } = data;
              const scriptElement = document.createElement('script');
              scriptElement.setAttribute('nonce', nonce);
              scriptElement.textContent = body;
              document.head.appendChild(scriptElement);
              // Google Maps JavaScript API loaded successfully
              resolve();
            },
            error: () => {
              console.error('Error loading Google Maps from server');
              reject();
            }
          });
        });
      }
      else {
        resolve();
      }
    });
  }

  drawLines(): void {
    // Define a symbol using a predefined path (an arrow)
    // supplied by the Google Maps JavaScript API.
    let lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    };
    this.lines = [];

    this.locations.reduce((previousLocation, currentLocation, index) => {
      if (index !== 0 || previousLocation.equals(currentLocation)) {
        let line = new google.maps.Polyline({
          path: [previousLocation, currentLocation],
          geodesic: true,
          strokeColor: '#f4415c',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          icons: [{
            icon: lineSymbol,
            offset: '100%',
          }],
        });
        this.lines.push(line);
      }
      return currentLocation;
    })

  }

  showLines(): void {
    this.lines.forEach(line => line.setMap(this.map));
  }

  hideLines(): void {
    this.lines.forEach(line => line.setMap(null));
  }
}