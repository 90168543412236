import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  SecurityContext
} from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute, Params } from "@angular/router";
import { CommonModule, DatePipe, NgSwitch } from "@angular/common";

import { SensorService } from "../shared/sensor.service";
import { ProjectService } from "../shared/project.service";
import { CapitalizePipe } from "../shared/capitalize.pipe";
import { DataFormats } from "./../shared/dataformats";
import { Map } from "../map/map";
import { RoundPipe } from "./../shared/round.pipe";
import { dataTypes } from "./../shared/global";
import { Subscription } from "rxjs";
//import { error } from "util";
import { AlertService } from "../shared/alert.service";
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;
declare var Chartist: any;
declare const google: any;

@Component({
  templateUrl: "./sensor.component.html",
  providers: [ProjectService, SensorService, AlertService]
})
export class SensorComponent implements OnInit, OnDestroy {
  triggerAPISubscription: Subscription;

  dataFormats: DataFormats[];
  currentSensor: string;
  currentSensorNickname: string;
  nickname: string;
  currentProject: string;
  graphData: {};
  alerts = {};
  triggeredAlerts = [];
  valuesForGroup = [];
  widgetStyle = {};
  unitsForData = [];
  entries: number;
  latestBattery = 100;
  latestConnection: string = "";
  latestNetwork: string = "";
  dataLoaded = false;
  chartLeft;
  chartRight;
  chartBig;
  chartLeftMeasurement;
  chartRightMeasurement;
  chartBigMeasurement;
  labels = [];
  labelsBig = [];
  dates = [];
  startDate: Date;
  startDateString: string;
  startDateTime: string;
  endDate: Date;
  endDateString: string;
  endDateTime: string;
  minValues = [];
  maxValues = [];
  avgValues = [];
  latestValues: number[] = [];
  saveSuccess: boolean = false;
  lastUpdated: string;
  timer: any;
  mapDataLoaded: boolean = true;
  showingLatestData: boolean = true;
  updateTimer: any;
  xLabel: string = "HH:mm";
  valuesAreAverages: boolean = false;
  screenWidth: number;
  mobileWidhtLessThan: number = 768;
  showLinesToggle: boolean = false;
  apis = [];
  triggerStatus: string = "null";
  successfulTrigger: boolean = false;

  constructor(
    private projectService: ProjectService,
    private sensorService: SensorService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private roundPipe: RoundPipe,
    private map: Map,
    private sanitizer: DomSanitizer
  ) {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Method called when one of the date inputs is changed. Takes the current interval chosen and requests data for said interval
   */
  updateDate(): void {
    //yyyy-MM-dd, HH:mm
    if (this.showingLatestData) {
      this.showingLatestData = false;
      clearInterval(this.updateTimer);
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(
      function() {
        this.startDate.setDate(Number(this.startDateString.slice(8)));
        this.startDate.setMonth(Number(this.startDateString.slice(5, 7)) - 1);
        this.startDate.setFullYear(Number(this.startDateString.slice(0, 4)));
        this.startDate.setHours(Number(this.startDateTime.slice(0, 2)));
        this.startDate.setMinutes(Number(this.startDateTime.slice(3)));

        this.endDate.setDate(Number(this.endDateString.slice(8)));
        this.endDate.setMonth(Number(this.endDateString.slice(5, 7)) - 1);
        this.endDate.setFullYear(Number(this.endDateString.slice(0, 4)));
        this.endDate.setHours(Number(this.endDateTime.slice(0, 2)));
        this.endDate.setMinutes(Number(this.endDateTime.slice(3)));

        if (this.startDate > this.endDate) {
          let temp = this.startDate;
          this.startDate = this.endDate;
          this.endDate = temp;

          this.startDateString = "0000-00-00";
          this.endDateString = this.datePipe.transform(
            this.endDate,
            "yyyy-MM-dd"
          );
          this.endDateTime = this.datePipe.transform(this.endDate, "HH:mm");
          this.startDateString = this.datePipe.transform(
            this.startDate,
            "yyyy-MM-dd"
          );
          this.startDateTime = this.datePipe.transform(this.startDate, "HH:mm");
        }
        this.showLoading();

        this.getSensorDataInterval();
        this.getIntervalMapData();
      }.bind(this),
      500
    ); // 0.5 s delay after change of input, to give user a chance to continue change date
  }

  getSensorDataInterval(): void {
    this.sensorService
      .getSensorDataInterval(
        this.currentSensor,
        this.currentProject,
        this.startDate,
        this.endDate
      )
      .then(data => {
        this.loadSensorData(data);
      });
  }

  getLatestSensorData(): void {
    this.showLoading();
    this.valuesAreAverages = true;
    this.sensorService
      .getLatestSensorData(this.currentSensor, this.currentProject)
      .then(data => {
        this.loadSensorData(data);

        this.startDate = this.dates[0];
        this.startDateString = this.datePipe.transform(
          this.startDate,
          "yyyy-MM-dd"
        );
        this.startDateTime = this.datePipe.transform(this.startDate, "HH:mm");
        this.endDate = this.dates[this.dates.length - 1];
        this.endDateString = this.datePipe.transform(
          this.endDate,
          "yyyy-MM-dd"
        );
        this.endDateTime = this.datePipe.transform(this.endDate, "HH:mm");
        this.lastUpdated = this.datePipe.transform(
          this.endDate,
          "EEEE dd MMM yyyy HH:mm"
        );
      });
  }

  clearAlertsForSensor() {
    this.sensorService
      .clearSensorAlerts(this.currentSensor, this.currentProject)
      .then(() => {
        this.triggeredAlerts = [];
        this.updateSensorIcons();
      })
      .catch(error => {
        //console.log("Could not clear alerts for sensor: " + error);
      });
  }

  getAlertsForSensor() {
    this.sensorService
      .getAlertsForSensor(this.currentSensor, this.currentProject)
      .then(foundAlerts => {
        foundAlerts.forEach(alert => {
          if (!this.alerts[alert.dataType]) this.alerts[alert.dataType] = [];
          this.alerts[alert.dataType].push(alert);
        });
        this.updateSensorIcons();
      })
      .catch(error => {
        //console.log("Alerts error: ", error);
      });
  }

  loadSensorData(sensorData): void {
    this.valuesAreAverages = false;
    //console.log("SENSORDATA: ");
    //console.log(sensorData);

    this.entries = Object.keys(sensorData).length;
    //If there is too many datapoints there will be an average of nearby datapoints

    const NumberOfEntriesToShow =
      this.screenWidth > this.mobileWidhtLessThan ? 80 : 40;
    if (this.entries > NumberOfEntriesToShow) {
      sensorData = this.dataAveraging(NumberOfEntriesToShow, sensorData);
    }

    if (this.entries > 0) {
      let i = this.entries - 1;
      do {
        this.latestBattery = this.roundPipe.transform(sensorData[i]["battery"]);
        if (i-- == 0) {
          this.latestBattery = 100;
        }
      } while (this.latestBattery == undefined); // Find latest battery, if none find, set to 100

      this.latestBattery = this.latestBattery < 0 ? 0 : this.latestBattery; // Round values above 100 to 100 and below 0 to 0
      this.latestBattery = this.latestBattery > 100 ? 100 : this.latestBattery;

      // Find latest battery, connection and network entry, if none find, set to default values
      i = this.entries - 1; 
      this.latestConnection = sensorData[i]["connection"];
      this.latestNetwork = sensorData[i]["network"];
      if(this.latestConnection == undefined) this.latestConnection = "";
      if(this.latestNetwork == undefined) this.latestNetwork ="";

      let temp = {};
      let avgEntries = [];

      this.dates = [];

      for (let entry of sensorData) {
        this.dates.push(new Date(parseInt(entry["time"]) * 1000));

        for (let value of this.valuesForGroup) {
          let toPush = 0 ;
          if (value !="transfer state total energy" && value !="work state total energy" && value !="idle state total energy" && value !="mean flow per shot")
          {
            toPush = this.roundPipe.transform(entry[value]);
          }
          else
          {
            toPush = this.roundPipe.transform3Decimal(entry[value]);
          }
          if (value === "battery") {
            toPush = toPush < 0 ? 0 : toPush;
            toPush = toPush > 100 ? 100 : toPush;
          }
          // Skips empty entries, but pushes them to graph in order to get the right dates
          if (toPush == undefined) {
            if (!temp[value]) {
              temp[value] = [];
              this.avgValues[value] = 0;
              this.minValues[value] = 0;
              this.maxValues[value] = 0;
              avgEntries[value] = 0;
            }
            temp[value].push({
              value: undefined
            });
            continue;
          }

          this.latestValues[value] = toPush;

          if (!temp[value]) {
            temp[value] = [];
            this.avgValues[value] = toPush;
            this.minValues[value] = toPush;
            this.maxValues[value] = toPush;
            avgEntries[value] = 1;
          } else {
            this.avgValues[value] = this.avgValues[value] + toPush;
            avgEntries[value]++;
            if (this.minValues[value] > toPush) {
              this.minValues[value] = toPush;
            } else if (this.maxValues[value] < toPush) {
              this.maxValues[value] = toPush;
            }
          }

          temp[value].push({
            value: toPush,
            meta: this.datePipe.transform(
              this.dates[this.dates.length - 1],
              "EEE MMM dd yyyy HH:mm:ss"
            ) //Also store date in tooltip. String decides format, checkout datepipe to customize.
          });
        }
      }
      for (let i in this.avgValues) {
        this.avgValues[i] /= avgEntries[i];
      }

      this.graphData = {};
      this.graphData = temp;
      this.formatLabels();
    }

    this.dataLoaded = true;
    this.hideLoading();

    if (this.entries > 0) {
      this.circlifulChart();
      $("#hideBattery").show();
      $("#graphSmallLeft").show();
      if (!this.mapDataLoaded) {
        $("#graphSmallRight").show();
      }
      $("#graphBig").show();
    } else {
      $("#hideBattery").hide();
      $("#graphSmallLeft").hide();
      $("#graphSmallRight").hide();
      $("#graphBig").hide();
    }
    this.initChartist();
  }
  /**
   * Method for reducing number of entries by calculating an average value of them.
   * @param NumberOfEntriesToShow
   * @param sensorData Data to average
   */
  dataAveraging(NumberOfEntriesToShow: number, sensorData: [any]): [any] {
    let sensorTempData: [any] = [0];
    const numberOfAggregates = Math.ceil(this.entries / NumberOfEntriesToShow);
    let index = 0;
    let aggregatedData = [];
    let avgEntries = [];
    let firstElement: boolean = true;
    sensorData.forEach(data => {
      aggregatedData.push(data);

      if (
        index !== 0 &&
        (index % numberOfAggregates === 0 || index === sensorData.length - 1)
      ) {
        let dataToPush = {};
        //Creates the values to aggregate
        for (let dataType of this.valuesForGroup) {
          dataToPush[dataType] = 0;
          avgEntries[dataType] = 0;
        }
        dataToPush["time"] = 0;
        avgEntries["time"] = 0;
        for (let data of aggregatedData) {
          for (let dataType in dataToPush) {
            if (data[dataType] != undefined) {
              avgEntries[dataType] += 1;
              dataToPush[dataType] += Number(data[dataType]);
            }
          }
        }
        for (let dataType in dataToPush) {
          dataToPush[dataType] /= avgEntries[dataType];
        }
        dataToPush["time"] = Math.round(dataToPush["time"]);

        if (firstElement) {
          sensorTempData[0] = dataToPush;
          firstElement = false;
        } else sensorTempData.push(dataToPush);
        aggregatedData = [];
      }
      index++;
    });

    this.entries = sensorTempData.length;
    this.valuesAreAverages = true;
    //console.log("sensorTempData");
    //console.log(sensorTempData);
    return sensorTempData;
  }

  getGroupDataFormats(): void {
    this.projectService
      .getGroupDataFormats(this.currentProject)
      .then(dataFormats => {
        this.dataFormats = dataFormats;
        //console.log("DATAFORMATS: ");
        //console.log(dataFormats);
        if (this.dataFormats.length > 0) {
          for (let dataformat of this.dataFormats) {
            if (
              !dataTypes.exclude.some(
                type =>
                  String(type).toLowerCase() ===
                  String(dataformat["dataType"]).toLowerCase()
              )
            ) {
              // Checks if current datatype is in the 'exclude'-list
              this.valuesForGroup.push(dataformat["dataType"]);
            }

            this.widgetStyle[
              dataformat["dataType"]
            ] = this.getIconClassForDataFormat(dataformat);
            this.unitsForData[dataformat["dataType"]] = dataformat["unit"];
          }

          this.valuesForGroup.push("battery");

          this.chartLeftMeasurement = this.valuesForGroup[0];
          if (this.valuesForGroup.length > 2) {
            this.chartRightMeasurement = this.valuesForGroup[1];
            this.chartBigMeasurement = this.valuesForGroup[2];
          } else if (this.valuesForGroup.length > 1) {
            this.chartRightMeasurement = this.valuesForGroup[0];
            this.chartBigMeasurement = this.valuesForGroup[1];
          } else {
            this.chartRightMeasurement = this.valuesForGroup[0];
            this.chartBigMeasurement = this.valuesForGroup[0];
          }

          this.getLatestSensorData();
        } else {
          //console.log("No dataformats for group found");
          this.dataLoaded = false;
        }
      });
  }

  updateSensorIcons() {
    if (!this.dataFormats) return;
    this.dataFormats.forEach(el => {
      this.widgetStyle[el["dataType"]] = this.getIconClassForDataFormat(el);
    });
  }

  getIconClassForDataFormat(dataformat) {
    let dataTypeTrigger = false;
    if (!this.triggeredAlerts) this.triggeredAlerts = [];
    this.triggeredAlerts.forEach(alert => {
      if (this.alerts[dataformat["dataType"]]) {
        this.alerts[dataformat["dataType"]].forEach(element => {
          if (element._id === alert) {
            dataTypeTrigger = true;
          }
        });
      }
    });

    let dataType = {
      iconType: "",
      color: "",
      alertBorderColor: ""
    };
    dataType.iconType = dataformat["icon"];

    if (dataTypeTrigger) {
      dataType.color = "#f05050";
      dataType.alertBorderColor =  "#f05050"; 
    } else {
      dataType.color = "#81c868";
      dataType.alertBorderColor = "#fff";
    }
    return dataType;
  }

  getAPIs(): void {
    this.projectService
      .getAPIs(this.currentProject, this.currentSensor)
      .then(apis => {
        this.apis = apis;
        //console.log("apis");
        //console.log(apis);
      });
  }

  triggerAPI(routing: string): void {
    let body = { some: "body" };
    this.triggerAPISubscription = this.projectService
      .triggerAPI(routing, this.currentProject, this.currentSensor, body)
      .subscribe(response => {
        //console.log("response");
        //console.log(response);
        this.triggerStatus = routing;
        if (response.status == 200) this.successfulTrigger = true;
        else this.successfulTrigger = false;
        setTimeout(
          function() {
            if (this.triggerStatus === routing) {
              this.triggerStatus = null;
              this.successfulTrigger = false;
            }
            this.triggerAPISubscription.unsubscribe();
          }.bind(this),
          3000000
        );
      });
  }

  formatLabels(): void {
    let tempSmall = [];
    let tempBig = [];
    const nrOfLabelsSmall =
      this.screenWidth > this.mobileWidhtLessThan ? 10 : 5;
    const nrOfLabelsBig = this.screenWidth > this.mobileWidhtLessThan ? 25 : 5;
    if (this.dates[this.dates.length - 1] - this.dates[0] > 259200000) {
      // If first date is more than 3 days longer than last date, show dates instead of time
      this.xLabel = "dd MMM";
    } else {
      this.xLabel = "HH:mm";
    }

    for (let i = 0; i < this.dates.length; i++) {
      if (i % Math.ceil(this.entries / nrOfLabelsSmall) == 0) {
        tempSmall[i] = this.datePipe.transform(this.dates[i], this.xLabel);
      } else {
        tempSmall[i] = "";
      }

      if (i % Math.ceil(this.entries / nrOfLabelsBig) == 0) {
        tempBig[i] = this.datePipe.transform(this.dates[i], this.xLabel);
      } else {
        tempBig[i] = "";
      }
    }

    this.labels = tempSmall;
    this.labelsBig = tempBig;
  }

  markerTitleFunction(locationData, datePipe) {
    let time = new Date(Number(locationData["time"]) * 1000);
    let timeString = datePipe.transform(time, "EEE d MMM yyyy HH:mm:ss");
    return timeString;
  }

  // Markers for map
  markerFunction(locationData, datePipe) {
    let time = new Date(Number(locationData["time"]) * 1000);
    let timeString = datePipe.transform(time, "EEE d MMM yyyy HH:mm:ss");
    if (locationData["radius"] == undefined || locationData["radius"] === null)
      return "<p>" + timeString + "</p>";
    else
      return (
        "<p>" +
        timeString +
        "<br> Accuracy: " +
        locationData["radius"] +
        " meters </p>"
      );
  }

  toggleLines() {
    if (this.showLinesToggle) {
      this.map.showLines();
    } else {
      this.map.hideLines();
    }
  }

  getLatestMapData(): void {
    if (this.mapDataLoaded == false) {
      this.mapDataLoaded = true;
      this.map.init(this.markerFunction, this.markerFunction, [
        { lat: 58.416279, long: 15.560577, time: 1511522502 }
      ]);
    }
    this.sensorService
      .getLatestSensorGeoData(this.currentSensor, this.currentProject)
      .then(data => {
        //console.log("MAPDATA: ");
        //console.log(data);
        if ((this.mapDataLoaded = data.length > 0)) {
          this.mapDataLoaded = true;
          this.map.init(this.markerFunction, this.markerTitleFunction, data);
          this.toggleLines();
        } else {
          this.mapDataLoaded = false;
        }
      });
  }
  getIntervalMapData(): void {
    if (this.mapDataLoaded == false) {
      this.mapDataLoaded = true;
      this.map.init(this.markerFunction, this.markerFunction, [
        { lat: 58.416279, long: 15.560577, time: 1511522502 }
      ]);
    }
    this.sensorService
      .getSensorGeoDataInterval(
        this.currentSensor,
        this.currentProject,
        this.startDate,
        this.endDate
      )
      .then(data => {
        //console.log("MAPDATA: ");
        //console.log(data);
        if ((this.mapDataLoaded = data.length > 0)) {
          this.mapDataLoaded = true;
          this.map.init(this.markerFunction, this.markerTitleFunction, data);
          this.toggleLines();
        } else {
          this.mapDataLoaded = false;
        }
      });
  }

  initChartist(): void {
    this.chartLeft = new Chartist.Line(
      "#graph-left",
      {
        labels: this.labels,
        series: [this.graphData[this.chartLeftMeasurement]]
      },
      {
        low: this.getChartLow(
          this.graphData[this.chartLeftMeasurement],
          this.alerts[this.chartLeftMeasurement]
        ),
        high: this.getChartHigh(
          this.graphData[this.chartLeftMeasurement],
          this.alerts[this.chartLeftMeasurement]
        ),
        axisY: {
          labelInterpolationFnc: function(value) {
            // Will return Mon, Tue, Wed etc. on medium screens
            return Math.round(value * 10) / 10;
          }
        },
        showArea: true,
        plugins: [
          Chartist.plugins.tooltip(),
          Chartist.plugins.ctThreshold({
            alerts: this.alerts[this.chartLeftMeasurement],
            chartId: "left"
          }),
          Chartist.plugins.ctAxisTitle({
            axisX: {
              axisTitle:
                this.datePipe.transform(this.dates[0], "dd MMM yyyy HH:mm") +
                " to " +
                this.datePipe.transform(
                  this.dates[this.dates.length - 1],
                  "dd MMM yyyy HH:mm"
                ),
              axisClass: "ct-axis-title m-0 text-dark counter font-600",
              offset: {
                x: 0,
                y: 50
              }
            },
            axisY: {
              axisTitle:
                this.unitsForData[this.chartLeftMeasurement] === undefined
                  ? this.chartLeftMeasurement
                  : this.unitsForData[this.chartLeftMeasurement],
              axisClass: "ct-axis-title m-0 text-dark counter font-600",
              flipTitle: true
            }
          })
        ]
      }
    );

    this.chartRight = new Chartist.Line(
      "#graph-right",
      {
        labels: this.labels,
        series: [this.graphData[this.chartRightMeasurement]]
      },
      {
        low: this.getChartLow(
          this.graphData[this.chartRightMeasurement],
          this.alerts[this.chartRightMeasurement]
        ),
        high: this.getChartHigh(
          this.graphData[this.chartRightMeasurement],
          this.alerts[this.chartRightMeasurement]
        ),
        axisY: {
          labelInterpolationFnc: function(value) {
            // Will return Mon, Tue, Wed etc. on medium screens
            return Math.round(value * 10) / 10;
          }
        },
        showArea: true,
        plugins: [
          Chartist.plugins.tooltip(),
          Chartist.plugins.ctThreshold({
            alerts: this.alerts[this.chartRightMeasurement],
            chartId: "right"
          }),
          Chartist.plugins.ctAxisTitle({
            axisX: {
              axisTitle:
                this.datePipe.transform(this.dates[0], "dd MMM yyyy HH:mm") +
                " to " +
                this.datePipe.transform(
                  this.dates[this.dates.length - 1],
                  "dd MMM yyyy HH:mm"
                ),
              axisClass: "ct-axis-title m-0 text-dark counter font-600",
              offset: {
                x: 0,
                y: 50
              }
            },
            axisY: {
              axisTitle:
                this.unitsForData[this.chartRightMeasurement] === undefined
                  ? this.chartRightMeasurement
                  : this.unitsForData[this.chartRightMeasurement],
              axisClass: "ct-axis-title m-0 text-dark counter font-600",
              flipTitle: true
            }
          })
        ]
      }
    );

    this.chartBig = new Chartist.Line(
      "#graph-big",
      {
        labels: this.labelsBig,
        series: [this.graphData[this.chartBigMeasurement]]
      },
      {
        low: this.getChartLow(
          this.graphData[this.chartBigMeasurement],
          this.alerts[this.chartBigMeasurement]
        ),
        high: this.getChartHigh(
          this.graphData[this.chartBigMeasurement],
          this.alerts[this.chartBigMeasurement]
        ),
        axisY: {
          labelInterpolationFnc: function(value) {
            // Will return Mon, Tue, Wed etc. on medium screens
            return Math.round(value * 10) / 10;
          }
        },
        showArea: true,
        plugins: [
          Chartist.plugins.tooltip(),
          Chartist.plugins.ctThreshold({
            alerts: this.alerts[this.chartBigMeasurement],
            chartId: "big"
          }),
          Chartist.plugins.ctAxisTitle({
            axisX: {
              axisTitle:
                this.datePipe.transform(this.dates[0], "dd MMM yyyy HH:mm") +
                " to " +
                this.datePipe.transform(
                  this.dates[this.dates.length - 1],
                  "dd MMM yyyy HH:mm"
                ),
              axisClass: "ct-axis-title m-∂0 text-dark counter font-600",
              offset: {
                x: 0,
                y: 50
              }
            },
            axisY: {
              axisTitle:
                this.unitsForData[this.chartBigMeasurement] === undefined
                  ? this.chartBigMeasurement
                  : this.unitsForData[this.chartBigMeasurement],
              axisClass: "ct-axis-title m-0 text-dark counter font-600",
              flipTitle: true
            }
          })
        ]
      }
    );
  }

  arrayMax(arr): Number {
    return arr.reduce(function(p, v) {
      return p > v ? p : v;
    });
  }

  arrayMin(arr): Number {
    return arr.reduce(function(p, v) {
      return p < v ? p : v;
    });
  }

  callChangeValueLeft(measurement): void {
    let chartToUpdate = "chart-left";
    this.updateChartist(chartToUpdate, measurement);
  }

  callChangeValueRight(measurement): void {
    let chartToUpdate = "chart-right";
    this.updateChartist(chartToUpdate, measurement);
  }

  callChangeValueBig(measurement): void {
    let chartToUpdate = "chart-big";
    this.updateChartist(chartToUpdate, measurement);
  }

  updateChartist(chartToUpdate, measurement): void {
    let data = {
      labels: this.labels,
      series: this.graphData[measurement]
    };

    switch (chartToUpdate) {
      case "chart-left":
        this.chartLeftMeasurement = measurement;
        this.chartLeft = new Chartist.Line(
          "#graph-left",
          {
            labels: this.labels,
            series: [this.graphData[this.chartLeftMeasurement]]
          },
          {
            low: this.getChartLow(
              this.graphData[this.chartLeftMeasurement],
              this.alerts[this.chartLeftMeasurement]
            ),
            high: this.getChartHigh(
              this.graphData[this.chartLeftMeasurement],
              this.alerts[this.chartLeftMeasurement]
            ),
            axisY: {
              labelInterpolationFnc: function(value) {
                // Will return Mon, Tue, Wed etc. on medium screens
                return Math.round(value * 10) / 10;
              }
            },
            showArea: true,
            plugins: [
              Chartist.plugins.tooltip(),
              Chartist.plugins.ctThreshold({
                alerts: this.alerts[this.chartLeftMeasurement],
                chartId: "left"
              }),
              Chartist.plugins.ctAxisTitle({
                axisX: {
                  axisTitle:
                    this.datePipe.transform(
                      this.dates[0],
                      "dd MMM yyyy HH:mm"
                    ) +
                    " to " +
                    this.datePipe.transform(
                      this.dates[this.dates.length - 1],
                      "dd MMM yyyy HH:mm"
                    ),
                  axisClass: "ct-axis-title m-0 text-dark counter font-600",
                  offset: {
                    x: 0,
                    y: 50
                  }
                },
                axisY: {
                  axisTitle:
                    this.unitsForData[this.chartLeftMeasurement] === undefined
                      ? this.chartLeftMeasurement
                      : this.unitsForData[this.chartLeftMeasurement],
                  axisClass: "ct-axis-title m-0 text-dark counter font-600",
                  flipTitle: true
                }
              })
            ]
          }
        );
        break;
      case "chart-right":
        this.chartRightMeasurement = measurement;
        this.chartRight = new Chartist.Line(
          "#graph-right",
          {
            labels: this.labels,
            series: [this.graphData[this.chartRightMeasurement]]
          },
          {
            low: this.getChartLow(
              this.graphData[this.chartRightMeasurement],
              this.alerts[this.chartRightMeasurement]
            ),
            high: this.getChartHigh(
              this.graphData[this.chartRightMeasurement],
              this.alerts[this.chartRightMeasurement]
            ),
            axisY: {
              labelInterpolationFnc: function(value) {
                // Will return Mon, Tue, Wed etc. on medium screens
                return Math.round(value * 10) / 10;
              }
            },
            showArea: true,
            plugins: [
              Chartist.plugins.tooltip(),
              Chartist.plugins.ctThreshold({
                alerts: this.alerts[this.chartRightMeasurement],
                chartId: "right"
              }),
              Chartist.plugins.ctAxisTitle({
                axisX: {
                  axisTitle:
                    this.datePipe.transform(
                      this.dates[0],
                      "dd MMM yyyy HH:mm"
                    ) +
                    " to " +
                    this.datePipe.transform(
                      this.dates[this.dates.length - 1],
                      "dd MMM yyyy HH:mm"
                    ),
                  axisClass: "ct-axis-title m-0 text-dark counter font-600",
                  offset: {
                    x: 0,
                    y: 50
                  }
                },
                axisY: {
                  axisTitle:
                    this.unitsForData[this.chartRightMeasurement] === undefined
                      ? this.chartRightMeasurement
                      : this.unitsForData[this.chartRightMeasurement],
                  axisClass: "ct-axis-title m-0 text-dark counter font-600",
                  flipTitle: true
                }
              })
            ]
          }
        );
        break;
      case "chart-big":
        this.chartBigMeasurement = measurement;
        this.chartBig = new Chartist.Line(
          "#graph-big",
          {
            labels: this.labelsBig,
            series: [this.graphData[this.chartBigMeasurement]]
          },
          {
            low: this.getChartLow(
              this.graphData[this.chartBigMeasurement],
              this.alerts[this.chartBigMeasurement]
            ),
            high: this.getChartHigh(
              this.graphData[this.chartBigMeasurement],
              this.alerts[this.chartBigMeasurement]
            ),
            axisY: {
              labelInterpolationFnc: function(value) {
                // Will return Mon, Tue, Wed etc. on medium screens
                return Math.round(value * 10) / 10;
              }
            },
            showArea: true,
            plugins: [
              Chartist.plugins.tooltip(),
              Chartist.plugins.ctThreshold({
                alerts: this.alerts[this.chartBigMeasurement],
                chartId: "big"
              }),
              Chartist.plugins.ctAxisTitle({
                axisX: {
                  axisTitle:
                    this.datePipe.transform(
                      this.dates[0],
                      "dd MMM yyyy HH:mm"
                    ) +
                    " to " +
                    this.datePipe.transform(
                      this.dates[this.dates.length - 1],
                      "dd MMM yyyy HH:mm"
                    ),
                  axisClass: "ct-axis-title m-0 text-dark counter font-600",
                  offset: {
                    x: 0,
                    y: 50
                  }
                },
                axisY: {
                  axisTitle:
                    this.unitsForData[this.chartBigMeasurement] === undefined
                      ? this.chartBigMeasurement
                      : this.unitsForData[this.chartBigMeasurement],
                  axisClass: "ct-axis-title m-0 text-dark counter font-600",
                  flipTitle: true
                }
              })
            ]
          }
        );
        break;
    }
  }

  setSensorNickname(): void {
    this.sensorService
      .setSensorNickname(this.currentSensor, this.currentProject, this.nickname)
      .then(data => {
        //console.log("Tried to change sensor nickname: " + data);
        this.sensorService
          .getSensor(this.currentSensor, this.currentProject)
          .then(data => {
            this.currentSensorNickname = this.sanitizer.sanitize(SecurityContext.HTML, data[this.currentSensor]);
            this.saveSuccess = true;
            setTimeout(
              function() {
                this.saveSuccess = false;
              }.bind(this),
              3000000
            );
          });
      });
  }

  getChartHigh(chartData, alerts) {
    //console.log("alerts", alerts);
    //console.log("chartData", chartData);
    
    const dataCopy = chartData.slice(0);
    let max = null;
    dataCopy.forEach(element => {
      if (max === null && element.value !== undefined) max = element.value;
      if (!Number.isNaN(element.value)) max = element.value > max ? element.value : max;
    });

    if (!alerts) return max;
    alerts.forEach(alert => {
      if (max === null && alert.triggerValue !== undefined) max = alert.triggerValue;
      if (!Number.isNaN(alert.triggerValue)) max = alert.triggerValue > max ? alert.triggerValue: max;
    });
    //console.log("Returning max", max);
    
    // add some padding to max value
    return max > 0 ? max * 1.02 : max * 0.98;
  }

  getChartLow(chartData, alerts) {
    let min = null;
    if(chartData != undefined){
      const dataCopy = chartData.slice(0);
      dataCopy.forEach(element => {
        if (min === null && element.value !== undefined) min = element.value;
        if (!Number.isNaN(element.value)) min = element.value < min ? element.value : min;
      });
    }
    if (!alerts) return min;
    alerts.forEach(alert => {
      if (min === null && alert.triggerValue !== undefined) min = alert.triggerValue;
      if (!Number.isNaN(alert.triggerValue)) min = alert.triggerValue < min ? alert.triggerValue: min;
    });

    // add some padding to min value
    return min < 0 ? min * 1.02 : min * 0.98;
  }

  clearPopup(): void {
    this.nickname = "";
    this.saveSuccess = false;
  }

  @ViewChild("loading")
  modal: ElementRef;
  showLoading() {
    $("#loading.modal").modal("show");
  }
  hideLoading() {
    $("#loading.modal").modal("hide");
  }

  circlifulChart(): void {
    $("#latest-battery.circliful-chart")
      .empty()
      .removeData();
    $("#latest-battery.circliful-chart")
      .attr("data-percent", this.latestBattery)
      .attr("data-text", this.latestBattery + "%")
      .circliful();
  }

  goToGroup(project): void {
    let link = ["/dashboard", project];
    this.router.navigate(link);
  }

  goToHome(): void {
    let link = ["/"];
    this.router.navigate(link);
  }

  pauseInterval(): void {
    clearInterval(this.updateTimer);
  }

  continueInterval(): void {
    this.updateTimer = setInterval(
      function() {
        //console.log("Updating data of sensor " + this.currentSensor);
        this.getLatestSensorData();
        this.getLatestMapData();
      }.bind(this),
      30000000
    );
  }

  getSensor() {
    this.sensorService
      .getSensor(this.currentSensor, this.currentProject)
      .then(data => {
        this.currentSensorNickname = data[this.currentSensor].sensorNickname;
        this.triggeredAlerts = data[this.currentSensor].triggeredAlerts;
        this.updateSensorIcons();
      });
  }

  ngOnInit(): void {
    this.startDate = new Date(0);
    this.endDate = new Date();
    this.route.params.forEach((params: Params) => {
      this.currentSensor = params["sensor"];
      {
      }
      this.currentProject = params["project"];
    });
    this.getGroupDataFormats();
    this.getAPIs();

    this.route.params.subscribe(val => {
      clearInterval(this.updateTimer);
      this.mapDataLoaded = true;
      this.getAlertsForSensor();
      this.map.loadMap().then(() => {
      this.map.init(this.markerFunction, this.markerFunction, [
        {
          lat: 58.416279,
          long: 15.560577,
          sensorName: "NotASensor",
          time: 1511522502
        }
      ]);
      this.getLatestMapData();
    });
      this.getLatestSensorData();

      this.updateTimer = setInterval(
        function() {
          //console.log("Updating data of sensor " + this.currentSensor);
          this.getLatestSensorData();
          this.getLatestMapData();
        }.bind(this),
        60000
      );

      this.getSensor();
    });
  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    //console.log("On destroy: clear interval for sensor");
    clearInterval(this.updateTimer);
  }
}
